import React from 'react';
import Lottie from 'react-lottie';


const AnimationComponent = ({ title, options, index }) => {
  const baseDelay = 500; 
  const delay = baseDelay + index * 100; 

  return (
    <header className="uiux-header" data-aos="fade-up" data-aos-delay={delay}>
      <div className="section-title">{title}</div>
      <Lottie options={options} height={50} width={45} />
    </header>
  );
};

export default AnimationComponent;