import { motion } from 'framer-motion';

const Card = ({ content, index, mouseX, mouseY }) => {
  return (
    <motion.div
      onMouseMove={(e) => {
        mouseX.set(e.clientX - window.innerWidth / 2);
        mouseY.set(e.clientY - window.innerHeight / 2);
      }}
      onMouseLeave={() => {
        mouseX.set(0);
        mouseY.set(0);
      }}
      style={{
        width: 400,
        height: 505,
        // backgroundImage: `url(${content}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        cursor: 'pointer',
        perspective: '1000px',
        transform: `rotateY(${mouseX.get() / 20}deg) rotateX(${mouseY.get() / 20}deg)`,
        position: 'relative',
        transformStyle: 'preserve-3d',
      }}
      className='cardianimaxbox'
    >
      <motion.div
        style={{
          width: '100%',
          height: '100%',
          transformStyle: 'preserve-3d',
          transition: { duration: 0.5, ease: 'easeInOut' },
        }}
      >
        <label
          style={{
            color: 'white',
            fontSize: '24px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img className='animation-img-3d' src={content} alt='img' />
        </label>
      </motion.div>
    </motion.div>
  );
};


export default Card;