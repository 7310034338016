import React, { useState, useEffect, useRef } from "react";
import top from "../../assets/whatsapp.png";
import "../../assets/js/main";
import "../home/home.css";
import "../home/pradnya.css";
import "../home/custom";
import Footer from "../footer/footer";
import "animate.css/animate.min.css";
import Lottie from "react-lottie";
import AOS from "aos";
import "aos/dist/aos.css";
import "./marquee.css";
import Marquee from "react-fast-marquee";

// new imports from here
import icon1 from "../../assets/icon/1.png";
import icon2 from "../../assets/icon/2.png";
import icon3 from "../../assets/icon/3.png";
import icon4 from "../../assets/icon/4.png";
import icon5 from "../../assets/icon/5.png";
import SaaaLogo from "../../assets/icon/Logo.png"
import confused from "../../assets/icon/Confused.svg";
import wd from "../../assets/Home/wd-ill.webp";
import dm from "../../assets/Home/dm-ill.webp";
import fas from "../../assets/Home/fas-ill.webp";
import ic from "../../assets/Home/lc-ill.webp";
import seo from "../../assets/Home/seo-ill.webp";
import db from "../../assets/Home/db-ill.webp";
import grid1 from "../../assets/Home/grid1hover.png";
import grid2 from "../../assets/Home/grid2hover.png";
import grid3 from "../../assets/Home/grid3hover.png";
import grid4 from "../../assets/Home/grid4hover.png";
import grid5 from "../../assets/Home/grid5hover.png";
import grid6 from "../../assets/Home/grid6hover.png";
import grid7 from "../../assets/Home/grid7hover.png";
import grid8 from "../../assets/Home/grid8hover.png";
import grid9 from "../../assets/Home/grid9hover.png";
import grid10 from "../../assets/Home/grid10hover.png";
import grid11 from "../../assets/Home/grid11hover.png";
import grid12 from "../../assets/Home/grid12hover.png";
import grid13 from "../../assets/Home/grid13hover.png";
import grid14 from "../../assets/Home/grid14hover.webp";
import grid15 from "../../assets/Home/grid15hover.png";
import grid16 from "../../assets/Home/grid16hover.webp";
import grid17 from "../../assets/Home/grid17hover.webp";
import grid18 from "../../assets/Home/grid18hover.webp";
import grid19 from "../../assets/Home/grid19hover.webp";
import leftarrow from "../../assets/Home/leftarrow.svg";
import HomeSlider from "./PradnyHome";
import LastSlider from "./TestimonialSlider";
import plane from "../../assets/Home/plane.json";
import plane2 from "../../assets/Home/SAAA_Plane Animation 02.mp4.lottie.json";
import plane3 from "../../component/json/SAAA_Plane Animation 03.mp4.lottie.json";
import projectcounter from "../../assets/Home/projectscounter.webp";
import clientcounter from "../../assets/Home/clientscounter.webp";
import sectorcounter from "../../assets/Home/sectorscounter.webp";
import memberscounter from "../../assets/Home/memberscounter.webp";
import { useSpring, animated } from "react-spring";
import Loss from "../../assets/Home/Loss.svg";

const icons = [icon2, icon3, icon4, icon5, icon1];

const Home = () => {
  const testimonials = [
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      author: "- John Doe, CEO at Company",
      logos: [grid1, grid2],
    },
    // Add more testimonials here
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  const containerRef2 = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef2.current) {
      observer.observe(containerRef2.current);
    }

    return () => {
      if (containerRef2.current) {
        observer.unobserve(containerRef2.current);
      }
    };
  }, []);
  const containerRef3 = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef3.current) {
      observer.observe(containerRef3.current);
    }

    return () => {
      if (containerRef3.current) {
        observer.unobserve(containerRef3.current);
      }
    };
  }, []);

  const [animation, setAnimation] = useState(false);

  const props = useSpring({
    // opacity: animation ? 1 : 0,
    // transform: animation ? "scale(1)" : "scale(0)",
    config: { duration: 200 },
  });

  const spacing = 20;
  const startAnimation = () => {
    setAnimation(true);
  };

  const circleStyle = {
    position: "relative",
    width: "350px",
    height: "350px",
    border: "1px solid #fbb13c",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "auto",
  };
  const iconStyle = {
    position: "absolute",
    height: "30px",
    width: "30px",
  };


  return (
    <div className="homepage">
      <div className="back-2-top">
      <a href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20" target="_blank">
    <img src={top} alt="Whatsapp" />
  </a>
      </div>

      <section className="HomePage-Section">
        <div className="HomePageSlider">
          <HomeSlider />
        </div>
      </section>

      <section className="our-services">
      <div className="rocket-down" ref={containerRef}>
            {isVisible && (
              <Lottie
                options={{
                  autoplay: true,
                  loop: false,
                  animationData: plane,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                style={{ height: "20%", width: "30%", margin: 0 }}
              />
            )}
          </div>

        <div className="our-services-main-text">
          <h1>Our Services</h1>
          <h2>Perfect Solution for Your Business</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 hover-cards">
              <a href="/services/digital-marketing">
                <div class="card-body  full-card">
                  <h5 class="card-title head-card">Digital Marketing</h5>
                  <div className="card-img">
                    <img src={dm} alt="digital marketing" />
                  </div>

                  <div className="text-on-hover">
                    <p>
                      We create a strong digital presence and conversions
                      through our cross-channel strategies and top-notch digital
                      marketing services.
                    </p>
                    <img src={leftarrow} alt="arrow" />
                  </div>
                </div>
              </a>{" "}
            </div>

            <div className="col-lg-4 hover-cards">
              <a href="/services/web-development">
                <div class="card-body full-card">
                  <h5 class="card-title head-card">Web Development</h5>
                  <div className="card-img">
                    <div className="card-img">
                      <img src={wd} alt="Web Development"/>
                    </div>
                  </div>
                  <div className="text-on-hover">
                    <p>
                      We specialize in creating interactive & dynamic websites
                      and landing pages with engaging designs and content
                    </p>
                    <img src={leftarrow}  alt="arrow"/>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 hover-cards">
              <a href="/services/designing-branding">
                <div class="card-body full-card">
                  <h5 class="card-title head-card">Design / Branding</h5>
                  <div className="card-img">
                    <img src={db}  alt="design branding"/>
                  </div>
                  <div className="text-on-hover">
                    <p>
                      We focus on creating visually appealing interfaces that
                      resonate with your users and turn your ideas into engaging
                      user experiences that drive conversions
                    </p>
                    <img src={leftarrow}  alt="arrow"/>
                  </div>
                </div>
              </a>
            </div>
          </div>  
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 hover-cards">
              <a href="/services/ui-ux ">
                <div class="card-body full-card">
                  <h5 class="card-title head-card">UI / UX Design</h5>
                  <div className="card-img">
                    <img src={seo} alt="SEO" />
                  </div>
                  <div className="text-on-hover">
                    <p>
                      SEO plays a vital role in securing a steady and
                      sustainable stream of web traffic over the long run.
                    </p>
                    <img src={leftarrow} alt="arrow" />
                  </div>
                </div>
              </a>{" "}
            </div>
            {/* <div className="col-lg-4 hover-cards">
              <a href="/services/financial-advisory">
                <div class="card-body  full-card">
                  <h5 class="card-title head-card">
                    Financial Advisory Services
                  </h5>
                  <div className="card-img">
                    <img src={fas}  alt="Financial Advisory"/>
                  </div>
                  <div className="text-on-hover">
                    <p>
                      We deliver a broad range of financial advisory services to
                      a large and diversified client base that includes
                      Co-operation, Startups, Mom and Pop shops and Individuals.
                    </p>
                    <img src={leftarrow} alt="arrow" />
                  </div>
                </div>
              </a>{" "}
            </div> */}
            <div className="col-lg-4 hover-cards">
              <a href="/services/legal-compliance">
                <div class="card-body full-card">
                  <h5 class="card-title head-card">Legal Compliance</h5>
                  <div className="card-img">
                    <img src={ic} alt="Legal Compliance" />
                  </div>
                  <div className="text-on-hover">
                    <p>
                      We make sure that organizations and financial institutions
                      abide by the laws, policies, guidelines and other
                      standards that are suggested by regulators
                    </p>
                    <img src={leftarrow} alt="arrow"/>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn">
            <a href="/contact" smooth>
              <span>Know More</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </a>
          </div>
        </div>
      </section>
      <div className="our-services-main-text">
        <div className="rocket-down3" ref={containerRef3}>
          {isVisible && (
            <Lottie
              options={{
                autoplay: true,
                loop: false,
                animationData: plane3,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              style={{ height: "20%", width: "30%", margin: 0 }}
            />
          )}
        </div>
        <h1>Our Clients</h1>
        <h2>Those We Work Along Side</h2>
        <p>
          Embark on your digital success journey with us, where your triumphs
          become our shared story.
        </p>
      </div>
      <div className="marquee">
        <Marquee>
          <span>
            <img src={grid1} alt="Logo"/>
          </span>
          <span>
            <img src={grid2} alt="Logo"/>
          </span>
          <span>
            <img src={grid3} alt="Logo"/>
          </span>
          <span>
            <img src={grid4} alt="Logo"/>
          </span>
          <span>
            <img src={grid19} alt="Logo"/>
          </span>
          <span>
            <img src={grid18} alt="Logo"/>
          </span>
          <span>
            <img  src={grid5} alt="Logo"/>
          </span>
          <span>
            <img  src={grid6} alt="Logo"/>
          </span>
          <span>
            <img  src={grid7} alt="Logo"/>
          </span>
          <span>
            <img  src={grid8} alt="Logo"/>
          </span>
          <span>
            <img src={grid9} className="sootsparsh-logo lodash" />
          </span>
          <span>
            <img  src={grid10} alt="Logo"/>
          </span>

          <span>
            <img  src={grid12} alt="Logo"/>
          </span>
          <span>
            <img  src={grid13} alt="Logo"/>
          </span>
          <span>
            <img src={grid14} alt="Logo"/>
          </span>
          <span>
            <img src={grid15} alt="Logo"/>
          </span>
          <span>
            <img src={grid16} alt="Logo"/>
          </span>
          <span>
            <img src={grid17} alt="Logo"/>
          </span>
        </Marquee>
      </div>
      <div className="glance-main-text">
        <h1>At A Glance</h1>
        <h2>Quick Insight Into Our Company</h2>
        <p>
          Effortlessly navigate through our comprehensive services and discover
          the pathway to your digital excellence.
        </p>
        <div className="img-grid-grand">
          <div className="img-grid-parent">
            <div className="grid-inner1">
              <img src={projectcounter} alt="counter"/>
            </div>
            <div className="grid-inner2">
              <div className="grid-child1">
                <img src={sectorcounter} alt="counter"/>
              </div>
              <div className="grid-child1">
                <img src={clientcounter} alt="counter" />
              </div>
            </div>
            <div className="grid-inner3">
              <img src={memberscounter} alt="counter"/>
            </div>
          </div>
        </div>
      </div>
      <div className="why-us">
        {/* <div className="rocket-vid">
          <img src={rocket} />
        </div> */}
        <div className="why-us-text">
          <h1>What Sets Us Apart</h1>
          <h2>Optimal Solution For Your Business Success</h2>
          <p>
            We are a collective of dedicated individuals committed to
            transforming ideas into captivating digital experiences.
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="why-solution-content">
                <ul>
                  <li>
                    <div
                      class="right_content"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration={1500}
                    >
                      <h4>Innovative Customization</h4>
                      <p>
                        Our strategy centers on a research-focused approach,
                        delving deep to gather insights. By understanding your
                        business and market trends, we craft a customized
                        strategy for relentless growth and long-term brand
                        success.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      class="right_content"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration={1500}
                    >
                      <h4>Insightful Strategy</h4>
                      <p>
                        Thriving on creativity, our innovative process sets us
                        apart. We craft customized strategies for a personalized
                        experience tailored to your unique requirements.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      class="right_content"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration={1500}
                    >
                      <h4>Pinnacle Performance</h4>
                      <p>
                        Committed to excellence, our team has satisfied over 50
                        clients with relentless dedication. We strive for
                        perfection, consistently exceeding expectations.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="thinking-problem">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4">
                <h4 className="heading-one-solution">Multiple Problems?</h4>
                  <div className="multiple-line">

                 
                  <div className="line-icon-parent">
                    <div className="line-icon">
                      <img src={icon1} alt="icon1" />
                      <div className="line1"></div>
                    </div>
                    <div className="line-icon">
                      <img src={icon2} alt="icon1" />
                      <div className="line2"></div>
                    </div>
                    <div className="line-icon">
                      <img src={icon3} alt="icon1" />
                      <div className="line3"></div>
                    </div>
                    <div className="line-icon">
                      <img src={icon4} alt="icon1" />
                      <div className="line4"></div>
                    </div>
                    <div className="line-icon">
                      <img src={icon5} alt="icon1" />
                      <div className="line5"></div>
                    </div>
                  </div>
                   
                   <div className="loss-img">
                   <img src={Loss} alt="loss"/>
                   </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="confused-img">
                    <img src={confused} alt="confused" />
                  </div>
                </div>
                <div className="col-lg-4">
                <h4 className="heading-one-solution">One Solution !</h4>
                  <div className="solution-circle">
                    <div style={circleStyle}>
                      {icons.map((icon, index) => (
                        <animated.div
                          key={index}
                          style={{
                            ...props,
                            delay: index * 200,
                            ...iconStyle,
                            top: `${
                              60 -
                              15 +
                              50 *
                                Math.sin((index * (2 * Math.PI)) / icons.length)
                            }%`, // Adjust the radius as needed
                            left: `${
                              50 -
                              5 +
                              50 *
                                Math.cos((index * (2 * Math.PI)) / icons.length)
                            }%`, // Adjust the radius as needed
                            // transform: `rotate(${
                            //   index * (360 / icons.length)
                            // }deg)`,
                          }}
                          onMouseEnter={startAnimation}
                        >
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRight:
                                index < icons.length - 1
                                  ? `${spacing}px solid transparent`
                                  : "none",
                              boxSizing: "border-box",
                            }}
                          >
                            <img
                              src={icon}
                              alt={`icon-${index}`}
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>

                        </animated.div>
                      ))}
                      <img src={SaaaLogo} alt="saaa-logo" />
                          <p>
                            Everything Under
                            <br />
                            One Roof
                          </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      <section className="section">
        <div className="layout-parent-3">
          <div className="our-services-main-text">
            <h1>Testimonials</h1>
            <h2>Reviews from our clients</h2>
            <p>We are committed to redefining success by fostering creativity, inclusivity, and continuous learning!</p>
          </div>
          {/* <div className="testimonial-slider2">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-lg-12">
                  <TestimonialSlider />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section className="HomePage-Section5">
        <div className="rocket-down2" ref={containerRef2}>
          {isVisible && (
            <Lottie
              options={{
                autoplay: true,
                loop: false,
                animationData: plane2,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              style={{ height: "20%", width: "30%", margin: 0 }}
            />
          )}
        </div>
        <div className="HomePageSlider-test">
          <LastSlider />
        </div>
      </section>

      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
