import React from "react";
import quotesimg1 from "../../assets/mission/van-img1.webp";
import quotesimg2 from "../../assets/mission/van-img2.webp";
import quotesimg3 from "../../assets/mission/van-img3.webp";
import SliderComp from "../../component/about/books";
import SliderComp1 from "../../component/about/Writers";
import FilmsSlider from "../../component/about/Films";
import DirectorSlider from "../../component/about/Directors";
import PoetsSlider from "../../component/about/Poets";
import PhilosopherSlider from "../../component/about/Philosophers";
import ScientistSlider from "../../component/about/Scientists";
import SaintsSlider from "../../component/about/Saints";
import styled from "styled-components";
import "../OurInfluencers/OurInfluencers.css";
import Footer from "../footer/footer";
import top from "../../assets/whatsapp.png";
import Influencer from "../../assets/Home/influencershero.json";
import Lottie from "react-lottie";


const OurInfluencers = () => {

    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: Influencer,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <div>
        <div className="about-sec">
          <div className="back-2-top">
            <a
              href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
              target="_blank"
            >
              <img src={top} />
            </a>
          </div>
        </div>
        <div className="Influencershero">
          <div className="container-fluid pl-3">
            <div className="row justify-content-center align-items-center ">
              <div className="col-lg-6 col-sm-12">
                <div className="AboutHerodesc">
                  <p>Our Influencers</p>
                  <h1>
                  Our influencers have played a pivotal role in shaping our collective thoughts and fueling inspiration.
                  
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 ">
                <div className="AboutHeroimg">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: Influencer,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    style={{ height: "100%", width: "100%" }}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      <section style={{ backgroundColor: "#fff" }}>
        <div className="OurLives">
          <div className="container-fluid">
            <div className="quotes-heading ">
              <h1 className="text-center">
                Quotes That Gave Us Our Vision
              </h1>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4">
                <div className="quote-item ">
                  <img src={quotesimg1} alt="Quote 1" class="quote-img" />
                  <p>
                    Sometimes they say I’m mad but a grain of madness is the
                    best of Art.
                  </p>
                  <span></span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="quote-item ">
                  <img src={quotesimg2} alt="Quote 1" class="quote-img" />
                  <p>
                    What would life be, if we had no courage to attempt
                    anything?
                  </p>
                  <span></span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="quote-item ">
                  <img src={quotesimg3} alt="Quote 1" class="quote-img" />
                  <p>
                    I am seeking, I am striving, I am in it with all <br></br>{" "}
                    my heart.
                  </p>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="SliderSection">
        <div className="aboutSlider">
          <h1 className="text-center">
            Our Influencers
            <p className="text-center about-p">
              We pave our own path, set new standards, apply client centric
              approach and focus on objectives to help you grow.
            </p>
          </h1>

          <section className="SliderSection-One">
            <Container>
              <SliderComp />
            </Container>
          </section>
          <section className="SliderSection-Two">
            <Container>
              <FilmsSlider />
            </Container>
          </section>
          <section className="SliderSection-Three">
            <Container>
              <DirectorSlider />
            </Container>
          </section>
          <section className="SliderSection-Four">
            <Container>
              <PoetsSlider />
            </Container>
          </section>
          <section className="SliderSection-Five">
            <Container>
              <SliderComp1 />
            </Container>
          </section>
          <section className="SliderSection-Six">
            <Container>
              <PhilosopherSlider />
            </Container>
          </section>
          <section className="SliderSection-Seven">
            <Container>
              <ScientistSlider />
            </Container>
          </section>
          <section className="SliderSection-Eight">
            <Container>
              <SaintsSlider />
            </Container>
          </section>

          <div></div>
        </div>
      </section>
      <div className="betaj-badshah">
        <div className="container-fluid">
          <div className="betaj-head">
            <h1>Betaaj Badshahs</h1>
            <hr />

            <div className="betaj-allcards">
              <div class="betaj-card">
                <div class="card-body">
                  <h5 class="card-title">Parents</h5>
                  <hr />
                  <p class="card-text">
                    Our parents are our guiding lights. They inspire us with
                    their wisdom, sacrifice, and unyielding belief in our
                    potential. Their dedication and hard work serve as a model,
                    motivating us to reach for the stars.
                  </p>
                </div>
              </div>
              <div class="betaj-card">
                <div class="card-body">
                  <h5 class="card-title">Teachers</h5>
                  <hr />
                  <p class="card-text">
                    Teachers are our mentors and knowledge providers. They
                    inspire us with their passion for learning and their
                    commitment to our growth. Their guidance help us expand our
                    horizons and achieve our educational goals.
                  </p>
                </div>
              </div>
              <div class="betaj-card">
                <div class="card-body">
                  <h5 class="card-title">Friends</h5>
                  <hr />
                  <p class="card-text">
                    Friends make life brighter. Their unwavering belief, mutual
                    laughter, and support inspire us. Their friendship serves as
                    an inspiration for us to dream large and set lofty goals. We
                    are reminded that life's journey is brighter when we travel
                    it together with each experience and special moment.
                  </p>
                </div>
              </div>
              <div class="betaj-card">
                <div class="card-body">
                  <h5 class="card-title">Family</h5>
                  <hr />
                  <p class="card-text">
                    Our family is the foundation of our inspiration. The values
                    and strength we gain from our family shape our character and
                    give us the courage to pursue our dreams.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default OurInfluencers;
const Container = styled.div``;
