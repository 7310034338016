import React, { useState, useEffect, useRef } from "react";

import top from "../../assets/whatsapp.png";
import "animate.css/animate.min.css";
import Footer from "../footer/footer";
import "aos/dist/aos.css";
import useAosAndInView from "../scroll";
import "./plan.css";
import seo from "../../assets/Services/seo.webp";
import company from "../../assets/Services/company.webp";
import coorporate from "../../assets/Services/coorporate.webp";
import annaul from "../../assets/Services/annual.webp";
import legalhero from "../../assets/Services/legalhero.svg";
import { Link } from "react-router-dom";

const LegalCompliancePlans = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const sectionRef2 = useRef(null);
  const [isVisible3, setIsVisible3] = useState(false);
  const sectionRef3 = useRef(null);
  const [isVisible4, setIsVisible4] = useState(false);
  const sectionRef4 = useRef(null);
  const [isVisible5, setIsVisible5] = useState(false);
  const sectionRef5 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible3(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible4(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer5 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible5(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer3.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer4.observe(sectionRef4.current);
    }
    if (sectionRef5.current) {
      observer5.observe(sectionRef5.current);
    }

    // Cleanup
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer3.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer4.unobserve(sectionRef4.current);
      }
      if (sectionRef5.current) {
        observer5.unobserve(sectionRef5.current);
      }
    };
  }, []);

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setHasAnimated(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust as needed
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [hasAnimated]);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  return (
    <>
      <div className="services web-plans">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="Whatsapp" />
          </a>
        </div>
        <div className="main-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 web-hero-text">
                <div className="web-hero-div">
                  <p>Legal Compliance</p>
                  <h1>
                    Our team of experts offers Legal governance, Internal Audit,
                    risk management and compliance services to ease the complex
                    set of processes.
                  </h1>
                </div>
                <div className="service-btnhome smm-hero-btn">
                  <div className="service-actual-btn plan-slider-btn">
                    <Link to="/contact" smooth>
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5 web-hero-img">
                <img src={legalhero} alt="Legal Hero" />
              </div>
            </div>
          </div>
        </div>

        <div className="all-serv-sec legal-all-serv">
          <div className="serv1-full-sec">
            <div
              className={`serv1-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef}
            >
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Company Formation</h1>
                  <p>
                    We provide a seamless and efficient process for
                    incorporating your business. Our service covers everything
                    from selecting the right type of company structure to
                    preparing and filing the necessary documentation. For
                    instance in Singapore, this involves liaising with the
                    Accounting and Corporate Regulatory Authority (ACRA), while
                    in India, we navigate the complexities of registration with
                    the Ministry of Corporate Affairs (MCA) for issuance of
                    certificate of incorporation. This certification is a
                    testament to the company's compliance with the prescribed
                    Acts and guidelines enabling it to commence operations with
                    a legitimate corporate status
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <img src={company} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div
              className={`serv2-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef2}
            >
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <img src={coorporate} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Business Licensing</h1>
                  <p>
                  Beyond basic incorporation, certain business activities require specific licenses and permits. Our expertise extends to obtaining the necessary regulatory approvals and licenses for your business operations, tailored to the legal requirements, ensuring your company complies with all local laws and regulations.

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef3}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Annual Report Filing</h1>
                  <p>
                  Stay compliant with mandatory annual filings and reporting requirements. For instance, in Singapore, we assist with the annual returns and financial statements submissions to the prescribed authority and guide on proper correspondence with the MAS. For businesses in India, we manage the annual compliances including filings with the MCA, and ensure adherence to the Companies Act, with the objective that your business stays in good standing. Complying with these requirements ensures transparency, accountability, and regulatory adherence in corporate operations. 

                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <img src={annaul} alt="Service 1" />
              </div>
            </div>
          </div>
          {/* <div className="serv2-full-sec">
            <div
              className={`serv2-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef4}
            >
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <img src={annaul} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Annual Report Filing</h1>
                  <p>
                  Stay compliant with mandatory annual filings and reporting requirements. For instance, in Singapore, we assist with the annual returns and financial statements submissions to the prescribed authority and guide on proper correspondence with the MAS. For businesses in India, we manage the annual compliances including filings with the MCA, and ensure adherence to the Companies Act, with the objective that your business stays in good standing. Complying with these requirements ensures transparency, accountability, and regulatory adherence in corporate operations. 

                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn fas-chat-btn">
            <Link to="/contact" smooth>
              <span>Let's Chat</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default LegalCompliancePlans;
