import React, { useState, useEffect, useRef } from "react";
import top from "../../assets/whatsapp.png";
import ic1 from "../../assets/st.svg";
import ic2 from "../../assets/an.svg";
import ic3 from "../../assets/ui.svg";
import ic4 from "../../assets/web.svg";
import ic5 from "../../assets/user.svg";
import ic6 from "../../assets/deploy.svg";
import webhero from "../../assets/Services/webhero.svg";
import "animate.css/animate.min.css";
import Footer from "../footer/footer";
import "aos/dist/aos.css";
import "./plan.css";
import AOS from "aos";
import frontend from "../../assets/Services/frontend.webp";
import backend from "../../assets/Services/backend.webp";
import cms from "../../assets/Services/cms.webp";
import pgi from "../../assets/Services/payment.webp";
import database from "../../assets/Services/database.webp";
import html from "../../assets/Services/html.svg";
import css from "../../assets/Services/css.svg";
import react from "../../assets/Services/react.svg";
import php from "../../assets/Services/php.svg";
import vue from "../../assets/Services/vue.svg";
import wordpress from "../../assets/Services/wordpress.svg";
import wix from "../../assets/Services/wix.svg";
import strapi from "../../assets/Services/strapi.svg";
import shopify from "../../assets/Services/shopify.svg";
import { Link } from "react-router-dom";

const WebPlans = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [revealedItems, setRevealedItems] = useState([]);
  const [lineWidth, setLineWidth] = useState("0");
  const targetRef = useRef(null);
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const sectionRef2 = useRef(null);
  const [isVisible3, setIsVisible3] = useState(false);
  const sectionRef3 = useRef(null);
  const [isVisible4, setIsVisible4] = useState(false);
  const sectionRef4 = useRef(null);
  const [isVisible5, setIsVisible5] = useState(false);
  const sectionRef5 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible3(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible4(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer5 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible5(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer3.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer4.observe(sectionRef4.current);
    }
    if (sectionRef5.current) {
      observer5.observe(sectionRef5.current);
    }

    // Cleanup
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer3.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer4.unobserve(sectionRef4.current);
      }
      if (sectionRef5.current) {
        observer5.unobserve(sectionRef5.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update isVisible state based on the entry's intersection ratio
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is in the viewport
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const handleScroll = () => {
    const timelineItems = document.querySelectorAll(".timeline-items");
    timelineItems.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      if (rect.top < window.innerHeight * 0.75) {
        setRevealedItems((prevItems) =>
          prevItems.includes(index) ? prevItems : [...prevItems, index]
        );
        setLineWidth(`${(index / 6) * 60}%`);
      }
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const images = [ic1, ic2, ic3, ic4, ic5, ic6];

  const titles = [
    "STRATEGY & OUTLINE",
    "ANALYSIS & PLANNING",
    "UI/UX DESIGN",
    "WEB DEVELOPMENT",
    "USER TESTING",
    "DEPLOYMENT & SUPPORT",
  ];
  const content = [
    "Define strategic goals for evolving your idea into a successful map",
    "Identify requirements, define team structure and prepare product map",
    "Create seamless and effortless user experience with a polished design.",
    "Create seamless and effortless user experience with a polished design.",
    "Develop backend, API and mobile App following agile development process",
    "Launch on the website browser with support for future revisions",
  ];

  return (
    <>
      <div className="services web-plans">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="Whatsapp" />
          </a>
        </div>
        <div className="main-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 web-hero-text">
                <div className="web-hero-div">
                  <p>Web Development</p>
                  <h1>
                    We specialize in dynamic and responsive website development
                    to create websites that are optimized for performance and
                    usability.
                  </h1>
                </div>
                <div className="service-btnhome smm-hero-btn">
                  <div className="service-actual-btn plan-slider-btn">
                    <Link to="/contact" smooth>
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5 web-hero-img">
                <img src={webhero} alt="Web Develpment Hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="web-timeline">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="webplan-timeline-content">
                  <h2>Process</h2>
                  <p>
                    At SAAA, we're all about creating beautiful and
                    sophisticated websites. We pay super close attention to
                    every little detail, and we're really committed to making
                    your digital dreams come true. When we build your website,
                    we make sure it's amazing and gives your visitors an
                    experience that goes beyond what they expected.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="webplan-timeline-parent-ui">
                  <ul>
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                      <li key={index}>
                        <div
                          className={`timeline-items ${
                            revealedItems.includes(index) && "revealed"
                          }`}
                          data-aos="fade-up"
                          data-aos-delay={500 * index}
                        >
                          <div className="icons">
                            <img src={images[index]} alt="" />
                            <p>{index + 1}</p>
                          </div>
                          <div className="timeline-info">
                            <h6>{titles[index]}</h6>
                            <p>{content[index]}</p>
                          </div>
                        </div>
                        {index < 5 && (
                          <div
                            className={`timeline-line ${
                              revealedItems.includes(index) && "line-revealed"
                            }`}
                            data-aos="fade-right"
                            data-aos-delay={1000 + 500 * index}
                          ></div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="all-serv-sec">
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Front End Development</h1>
                  <p>
                    In the realm of digital marketing, SAAA Consultants ensures
                    your online presence's impactful first impression through
                    cutting-edge front-end development. As your dedicated
                    digital partners, our developers combine aesthetics with
                    functionality to craft immersive user experiences. Rooted in
                    user-centric design, our approach guarantees intuitive
                    clicks and compelling visual storytelling. From responsive
                    layouts to pixel-perfect designs, our services blend
                    creativity with technical precision, translating your vision
                    into a visually stunning and user-friendly interface.
                    Optimized for performance, our front-end solutions promise
                    swift loading times and seamless navigation. Trust SAAA
                    Consultants to elevate your online presence, creating a
                    captivating digital gateway that converts.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <img src={frontend} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={"serv2-sec"} ref={sectionRef2}>
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <img src={backend} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Back End Development</h1>
                  <p>
                    In the intricate world of digital marketing, SAAA
                    Consultants excels in Back-End Development—the unseen force
                    powering your online presence. Our developers craft the
                    digital backbone, from database management to server-side
                    scripting, ensuring precision and efficiency. What sets us
                    apart is a commitment to scalability and performance,
                    creating solutions that evolve with your business. Our
                    collaborative approach aligns technical requirements with
                    business objectives, architecting digital ecosystems that
                    empower your brand. At SAAA, Back-End Development is more
                    than code; it's building a foundation where reliability and
                    innovation converge. Trust us to fortify your online
                    presence, where every line of code elevates your digital
                    infrastructure to excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef3}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>CMS-Powered Websites</h1>
                  <p>
                    In the dynamic realm of digital marketing, SAAA Consultants
                    champions Content Management Systems (CMS) as the unsung
                    heroes empowering businesses to control their online
                    narratives. Specializing in CMS-powered websites, we provide
                    user-friendly, scalable solutions tailored to your needs, be
                    it WordPress, Drupal, or another platform. Our focus is not
                    just on stunning aesthetics but on easy manageability, even
                    for non-tech users. With SAAA, content updates are seamless,
                    keeping your site dynamic in the evolving digital sphere.
                    Our CMS-powered websites accommodate growth, changes, and
                    trends. Choosing SAAA is an investment in control—take
                    charge of your digital narrative, effortlessly update
                    content, and stay ahead with innovation and ease of use.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <img src={cms} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={`serv2-sec`} ref={sectionRef4}>
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <img src={pgi} alt="Service 1" className="lozad" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Payment Gateway Integration</h1>
                  <p>
                    SAAA Consultants excels in seamless payment gateway
                    integration, ensuring a smooth and secure transaction
                    experience for your customers. As your trusted digital
                    partners, we specialize in integrating leading payment
                    solutions, such as Razorpay, Stripe, and others, seamlessly
                    into your website or application. Our developers combine
                    expertise with user-centric design principles to create a
                    frictionless checkout process, enhancing customer
                    satisfaction and conversion rates. From SSL encryption to
                    PCI compliance, we prioritize security at every step,
                    safeguarding sensitive payment information. With SAAA
                    Consultants, you can rest assured that your online
                    transactions are protected while providing your customers
                    with a convenient and trustworthy payment experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef5}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Database Management</h1>
                  <p>
                    In the digital age, SAAA Consultants excels in crafting
                    robust database solutions, the backbone of a successful
                    online presence. Our twofold approach focuses on efficiency
                    and scalability, ensuring databases seamlessly handle
                    current needs and scale with business growth. From
                    relational databases to NoSQL solutions, we strategically
                    organize data for maximum efficiency. Security is
                    paramount—we implement state-of-the-art measures to
                    safeguard sensitive information, earning trust through
                    reliability. SAAA doesn't just manage databases; we
                    orchestrate the symphony of your digital data, turning it
                    into actionable insights. Choose us for Database Management
                    that transforms data into a strategic advantage, propelling
                    your success in the digital realm.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <img src={database} alt="Service 1" className="lozad" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn fas-chat-btn">
            <Link to="/contact" smooth>
              <span>Let's Chat</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </Link>
          </div>
        </div>
        {/* <div className="graphic-packages web-packages">
          <div className="web-packages-head">
            <h1>Website Development Plans</h1>
            <p>
              We have a team of expert website developers who listen to your
              every single need very carefully and execute the plan to save
              time.
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Starter</p>
                      <h3>
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        14,999*
                      </h3>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>On Page</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> CMS Wordpress
                            Website
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Upto 5 Pages
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Additional
                            Pages ₹500
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i>UI / UX package
                          </li>

                          <li>
                            <i className="fa-solid fa-check"></i> Hosting Free
                            For 1st Year
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Free SSL
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> 1 Email ID
                            With 500 MB Web Space
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Gallery
                            Section
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Social Profile
                            Link
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Click to
                            Call/Email
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> Mobile
                            Responsive
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> SEO Semantic
                            HTML Code
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Main Banner
                            Management
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> Blog
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>Testimonials
                            Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> SEO Plugin
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Google
                            Location Map
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> XML Sitemap
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Google
                            Analytics
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Website
                            Training Up to 2 Hrs
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> 1 Month
                            Maintenance free
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Wordpress
                            Login Details Will Be Given (Editor Access)
                          </li>
                        </ul>
                      </div>
                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Essential</p>
                      <h3>
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        34,999*
                      </h3>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> CMS Wordpress
                            Website
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Upto 5 Pages
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Additional
                            Pages ₹500
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>UI / UX package
                          </li>

                          <li>
                            <i className="fa-solid fa-check"></i> Hosting Free
                            For 1st Year
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Free SSL
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> 1 Email ID
                            With 500 MB Web Space
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Gallery
                            Section
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Social Profile
                            Link
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Click to
                            Call/Email
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Mobile
                            Responsive
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> SEO Semantic
                            HTML Code
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Main Banner
                            Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Blog
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>Testimonials
                            Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> SEO Plugin
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Google
                            Location Map
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> XML Sitemap
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Google
                            Analytics
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Website
                            Training Up to 2 Hrs
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> 1 Month
                            Maintenance free
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Wordpress
                            Login Details Will Be Given (Editor Access)
                          </li>
                        </ul>
                      </div>
                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>E-Commerce</p>
                      <h3>
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        64,999*
                      </h3>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>Custom Pricing</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> Customized
                            Design
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Free Hosting 1
                            Year
                          </li>

                          <li>
                            <i className="fa-solid fa-check"></i> SEO Ready
                            Website
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Business
                            E-Mail 10
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>SSL Certificate
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Control Panel
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Responsive
                            Design
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Content 1000
                            words
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Google
                            Analytics
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Search Console
                            Integration
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Payment
                            Gateway Integrate
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Website
                            Training Up to 4 Hrs
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> 3 Month
                            Maintenance free
                          </li>
                        </ul>
                      </div>
                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Custom</p>
                      <p>Design a custom package for your business needs</p>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>Custom Pricing</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> For company
                            with large transaction sizes or unique business
                            models
                          </li>
                        </ul>
                      </div>
                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="tech-timeline-content">
            <h2>Some Of The Technologies We Use</h2>
            <p>
              We have a team of expert website developers who listen to your
              every single need very carefully and execute the plan to save
              time.
            </p>
          </div>
        </div>
        <div className="logos-container">
          <img src={html} alt="Logo 1" className="logo lozad"/>
          <img src={css} alt="Logo 1" className="logo lozad" />
          <img src={react} alt="Logo 1" className="logo lozad" />
          <img src={php} alt="Logo 1" className="logo lozad" />
          <img src={vue} alt="Logo 1" className="logo lozad" />
          <img src={wordpress} alt="Logo 1" className="logo lozad" />
          <img src={wix} alt="Logo 1" className="logo lozad" />
          <img src={strapi} alt="Logo 1" className="logo lozad" />
          <img src={shopify} alt="Logo 1" className="logo lozad" />
        </div> */}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default WebPlans;
