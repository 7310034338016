import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Aos from 'aos';
import 'aos/dist/aos.css';

function useAosAndInView(delay) {
  const elementRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1200, delay: 0 }); // Add the 'delay' option here
    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        setInView(true);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [delay]);

  return { elementRef, inView };
}

export default useAosAndInView;
