import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import grid1 from "../../assets/Home/grid10hover.png";
import grid2 from "../../assets/Home/grid5hover.png";
import grid3 from "../../assets/Home/grid10hover.png";
import Sliderimg1 from "./img/quoet1.png";
import Sliderimg2 from "./img/quoute2.png";
import nextArrowImage from "./img/arrow-right-solid.svg";
import prevArrowImage from "./img/arrow-left-solid.svg";
import "../home/pradnya.css";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        right: "1.5rem",
        backgroundImage: `url(${nextArrowImage})`,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: "1.5rem",
        backgroundImage: `url(${prevArrowImage})`,
      }}
      onClick={onClick}
    />
  );
}

function AsNavFor() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const sliderSettings1 = {
    asNavFor: nav2,
    ref: (slider) => (sliderRef1.current = slider),
    speed: 2000,
    swipeToSlide: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderSettings2 = {
    asNavFor: nav1,
    ref: (slider) => (sliderRef2.current = slider),
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
    speed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <div className="container  justify-content-center">
        <div className="row test-box">
          <div className="text-right ">
            <div className="LogoSlide">
              <Slider {...sliderSettings1}>
                <div>
                  <img src={grid1} className="LogoSlideImg" />
                </div>
                <div>
                  <img src={grid2} className="LogoSlideImg" />
                </div>
                {/* <div>
                  <img src={grid3} className="LogoSlideImg" />
                </div> */}
              </Slider>
            </div>
          </div>
          <div className="col-lg-12 BS">
            <div className="slider-border"></div>
            <div className="text-center TextSlider">
              <img src={Sliderimg2} className="qouteImgUp" />
              <Slider {...sliderSettings2}>
                <div>
                  <p>
                    SAAA Consultants have been my extended digital and social
                    media marketing team. Their continuous inputs and many of
                    these are pro-active suggestions. They have gone much beyond
                    the original brief. I truly enjoy brainstorming with them on
                    all aspects of Zynk Star’s gaming concepts and its future
                    tech-developmental path. I thank you for being there on beck
                    and call and I definitely trust the team to think as if they
                    were in my shoes.
                  </p>
                  <p>
                    <b>Jayen Shah</b> <span> Mavuca Capital, Founder</span>
                  </p>
                </div>
                <div>
                  <p>
                    I highly recommend SAAA Digital for their exceptional
                    digital media services. The entire team are a bunch of true
                    professionals who have helped elevate our brand with their
                    expertise in social media marketing, website development,
                    fund raising, as well as presentation design. They are
                    responsive, proactive, and a pleasure to work with. SAAA
                    Digital is an invaluable partner, and I confidently
                    recommend their services to any organization looking to
                    improve their digital media presence.
                  </p>
                  <p>
                    <b>Ketan Mavinkurve</b> <span> AlphaCoach, Founder</span>
                  </p>
                </div>
                {/* <div>
                  <p>
                    WhizHack has been a pioneer in builidng a vertically
                    integrated cyber security organisation that creates a
                    sustainable secured ecosystem for its customers. From it's
                    early days as a startup, SAAA has been a solid digital
                    marketing partner as we positioned our capabilities in a
                    highly competitive market dominated by global behemoths.
                    Their relentless innovation has been driving our digital
                    marketing campaigns and managing company's social media.
                  </p>
                </div> */}
              </Slider>
              <img src={Sliderimg1} className="qouteImgDown" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AsNavFor;
