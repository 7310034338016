import React, { useState, useEffect, useRef } from "react";
import Landingvideo from "../../assets/home-video.mp4";
import mute from "../../assets/mute.svg";
import unmute from "../../assets/unmute.svg";
import "../Landingpage/landingpage.css";
import { HashLink } from "react-router-hash-link";

const Landingpage = () => {
  const [videoRatio, setVideoRatio] = useState();
  const [mutedVideo, setMutedVideo] = useState(true);
  const [isVideoInView, setIsVideoInView] = useState(false);
  const videoRef = useRef(null);

  return (
    <div className="landing-page">
      <div className="Landing-video">
        <div className="App-vid-test-about">
          <a
            onClick={() => setMutedVideo(!mutedVideo)}
            className={mutedVideo ? "active" : ""}
          >
            {mutedVideo ? <img src={mute} /> : <img src={unmute} />}
          </a>

          <video
            id="myVideo1"
            muted={mutedVideo}
            autoPlay={true}
            ratio={videoRatio}
            loop={true}
            playsInline={true}
            height="height99191"
            width="100%"
          >
            <source src={Landingvideo} type="video/mp4" />
          </video>
          
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
