import React, { useState, useEffect, useRef } from "react";

import top from "../../assets/whatsapp.png";

import webhero from "../../assets/Services/dbhero.svg";
import serv1 from "../../assets/Services/bg.webp";

import "animate.css/animate.min.css";
import Footer from "../footer/footer";
import "aos/dist/aos.css";
import useAosAndInView from "../scroll";
import "./plan.css";
import stars from "../../assets/Stars.svg";
import union from "../../assets/Services/Union.svg";
import visual from "../../assets/Services/visual.webp";
import Identity from "../../assets/Services/identity.webp";
import reels from "../../assets/Services/reels.webp";
import business from "../../assets/Services/business.webp";
import { Link } from "react-router-dom";

const Graphic = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const sectionRef2 = useRef(null);
  const [isVisible3, setIsVisible3] = useState(false);
  const sectionRef3 = useRef(null);
  const [isVisible4, setIsVisible4] = useState(false);
  const sectionRef4 = useRef(null);
  const [isVisible5, setIsVisible5] = useState(false);
  const sectionRef5 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible3(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible4(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer5 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible5(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer3.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer4.observe(sectionRef4.current);
    }
    if (sectionRef5.current) {
      observer5.observe(sectionRef5.current);
    }

    // Cleanup
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer3.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer4.unobserve(sectionRef4.current);
      }
      if (sectionRef5.current) {
        observer5.unobserve(sectionRef5.current);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  return (
    <>
      <div className="services web-plans">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="Whatsapp" />
          </a>
        </div>
        <div className="main-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 web-hero-text">
                <div className="web-hero-div">
                  <p>Design/Branding</p>
                  <h1>
                    Building a strong online presence is crucial for businesses.
                    That’s where our team of skilled graphic designers comes in.
                  </h1>
                </div>
                <div className="service-btnhome smm-hero-btn">
                  <div className="service-actual-btn plan-slider-btn">
                    <Link to="/contact" smooth>
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5 web-hero-img">
                <img src={webhero} alt="Development Hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="web-timeline">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="webplan-timeline-content">
                  <h2>Process</h2>
                  <p>
                    We believe that design is more than just aesthetics, it's
                    the visual language that speaks volumes about your brand.
                    Our graphic design expertise transforms concepts into
                    captivating visuals, ensuring your brand identity leaves an
                    indelible mark. With an unwavering commitment to precision
                    and creativity, we craft designs that resonate, captivate,
                    and set your brand apart.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 union-img">
                <img src={union} alt="Development hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="all-serv-sec">
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Visual Design</h1>
                  <p>
                  Visual Design is the creative heartbeat of graphic design, the
              secret sauce for captivating images, stunning websites, and
              eye-catching marketing materials. At its core, it's about making
              things visually appealing—dressing up content to impress with
              harmonious color schemes, fonts, images, and layout. Beyond
              aesthetics, Visual Design is a storyteller, conveying messages
              without words, setting moods, evoking emotions, and guiding
              viewers' eyes. It forms the face of your brand, ensuring memorable
              impressions. In web and app design, Visual Design organizes
              content for user-friendly experiences. It's the unsung hero,
              bringing beauty and functionality to everything you see. Trust
              Visual Design to work its magic, making a lasting impression in
              websites, logos, or marketing materials.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <img src={visual} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={`serv2-sec`} ref={sectionRef2}>
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <img src={Identity} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Identity Design</h1>
                  <p>
                    Elevate your brand in the competitive market with our
                    Identity Design services! Your brand's visual representation
                    and personality, the core of its identity, are crucial for
                    standing out and building trust. A well-crafted identity
                    design, including logos, fonts, and style guides, sets you
                    apart and communicates values clearly. Our expert designers
                    ensure cohesion across all marketing materials for a
                    consistent brand language. From your website to business
                    cards and packaging, uniformity leaves a lasting impression.
                    Ready to transform your business and resonate with your
                    audience? Our Identity Design services work magic—contact us
                    today and watch your brand become a standout success!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef3}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Ad Creatives</h1>
                  <p>
                    Ad creatives, the secret weapon in graphic design, are the
                    storytellers of advertising, using images and design to
                    convey your brand's narrative. They act as captivating book
                    covers, instantly grabbing attention in the noisy digital
                    landscape, making your content stand out like a spotlight in
                    a crowded room. Ad creatives create lasting impressions,
                    ensuring your brand is memorable to the audience. Designed
                    to drive conversions, they encourage action—clicks,
                    purchases, or newsletter sign-ups. At SAAA, we excel in
                    crafting ad creatives for social media, banners, and email
                    campaigns. Let's bring the power of ad creatives to make
                    your brand the center of attention! Contact us today to
                    supercharge your visual storytelling with effective ad
                    creatives.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <img src={serv1} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={`serv2-sec`} ref={sectionRef4}>
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <img src={reels} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Reels & Display Banner</h1>
                  <p>
                    Elevate your digital presence with our innovative Reels,
                    Video Services, and Display Banners in graphic design.
                    Imagine captivating short videos and striking digital
                    billboards transforming static graphics into dynamic,
                    attention-grabbing content. Here's why you need them: Both
                    videos and display banners deliver Visual Impact
                    efficiently, conveying information swiftly while leaving a
                    lasting impression. They excel in Storytelling, narrating
                    brand journeys or simplifying complex concepts engagingly,
                    forging deeper connections with your audience. Encouraging
                    Engagement is their forte, as they prompt interaction, from
                    likes and shares to comments, enhancing your online presence
                    and fostering community engagement. Diverse content keeps
                    audiences interested, and by incorporating a mix of videos
                    and banners, you cater to various preferences, capturing a
                    broader audience. Ready to bring your graphics to life and
                    make a significant impact online? Contact us today to
                    explore the exciting world of Reels, Video, and Display
                    Banners—your brand's visual journey is about to get much
                    more exciting!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef5}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Business Communications</h1>
                  <p>
                    In the business world, effective communication blends what
                    you say with how you say it, and visual appeal is paramount.
                    Our graphic design services ensure: 1. Eye-Catching Visuals:
                    Our designers create stunning visuals, from logos to
                    infographics, leaving lasting impressions. 2. Branding
                    Power: We help forge a unique and memorable brand for
                    instant recognition. 3. Storytelling: Graphics enhance your
                    storytelling powerfully and engagingly, transcending
                    limitations of words. 4. Consistency Matters: Uniform design
                    builds trust and professionalism, aligning with your brand's
                    identity. 5. Digital Presence: From websites to social
                    media, we ensure you stand out in the virtual crowd. Graphic
                    design is your secret weapon for impactful, cohesive, and
                    professional business communication. It's about making a
                    lasting impact beyond pretty pictures. Ready to elevate your
                    brand's visual story? Contact us today!
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <img src={business} alt="Service 1" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn fas-chat-btn">
            <Link to="/contact" smooth>
              <span>Let's Chat</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </Link>
          </div>
        </div>
        {/* <div className="graphic-packages web-packages">
          <div className="web-packages-head">
            <h1>Design / Branding Plans</h1>
            <p>
              We have a team of expert website developers who listen to your
              every single need very carefully and execute the plan to save
              time.
            </p>
          </div>
          <div className="graphic-packages">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 graphic-popular">
                  <div className="plan-top">
                    <p>Most Popular</p>
                  </div>
                  <div className="plan-item">
                    <div className="plan-outer">
                      <div className="plan-head">
                        <p>Starter</p>
                        <h3>
                          <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                          21,999*
                        </h3>
                      </div>
                      <hr />

                      <div className="plan-body">
                        <div className="plan-inner">
                          <ul>
                            <li>
                              <i className="fa-solid fa-check"></i> Brief Study
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Competitor
                              Analysis
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Brand Kit
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Iterations
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> 2 Revisions
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Logo
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Logo
                              Animation
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Presentation
                              Template (on demand)
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Full
                              Presentation / Pitch Deck{" "}
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Business
                              Cards
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Brochure /
                              Flyers / Pamphlets (On Demand)
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Social Media
                              Ad Design (on demand)
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Video (as
                              per requirement)
                            </li>
                          </ul>
                        </div>

                        <div className="service-btnhome">
                          <div className="service-actual-btn plans-chat-btn">
                            <Link to="/contact" smooth>
                              <span>Let's Chat</span>
                              <i className="fa-solid fa-arrow-right arrow-services"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 graphic-popular">
                  <div className="plan-top">
                    <p>Most Popular</p>
                  </div>
                  <div className="stars">
                    <img src={stars} />
                  </div>
                  <div className="plan-item">
                    <div className="plan-outer">
                      <div className="plan-head">
                        <p>Essential</p>
                        <h3>
                          <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                          39,999*
                        </h3>
                      </div>
                      <hr />
                      <div className="plan-body">
                        <div className="plan-inner">
                          <ul>
                            <li>
                              <i className="fa-solid fa-check"></i> Brief Study
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Competitor
                              Analysis
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Brand Kit
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Iterations
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> 2 Revisions
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Logo
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Logo
                              Animation
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Presentation
                              Template (on demand)
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Full
                              Presentation / Pitch Deck{" "}
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Business
                              Cards
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Brochure /
                              Flyers / Pamphlets (On Demand)
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Social Media
                              Ad Design (on demand)
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Video (as
                              per requirement)
                            </li>
                          </ul>
                        </div>

                        <div className="service-btnhome">
                          <div className="service-actual-btn plans-chat-btn">
                            <Link to="/contact" smooth>
                              <span>Let's Chat</span>
                              <i className="fa-solid fa-arrow-right arrow-services"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 graphic-popular">
                  <div className="plan-top">
                    <p>Most Popular</p>
                  </div>
                  <div className="plan-item">
                    <div className="plan-outer">
                      <div className="plan-head">
                        <p>Custom</p>
                        <p>Design a custom package for your business needs</p>
                      </div>
                      <hr />
                      <div className="plan-body">
                        <div className="plan-inner">
                          <p>Custom Pricing</p>
                          <ul>
                            <li>
                              <i className="fa-solid fa-check"></i> For company
                              with large transaction sizes or unique business
                              models
                            </li>
                          </ul>
                        </div>

                        <div className="service-btnhome">
                          <div className="service-actual-btn plans-chat-btn">
                            <Link to="/contact" smooth>
                              <span>Let's Chat</span>
                              <i className="fa-solid fa-arrow-right arrow-services"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default Graphic;
