  import React, { useState, useEffect, useRef } from "react";
  import { Link } from "react-router-dom";
  import "./Navbar.css";

  import logo from "../../assets/logo.png";
  import { GiHamburgerMenu } from "react-icons/gi";
  import { ImCross } from "react-icons/im";

  const Navbar2 = () => {
    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    const navButton = useRef(null);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [isNavShowing, setIsNavShowing] = useState(false);
    useEffect(() => {
      const currentScrollPos1 = window.scrollY;
      if (currentScrollPos1 === 0) {
     
      }
    });

    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.scrollY;

        if (currentScrollPos > prevScrollPos) {
          setVisible(false);
        } else if (currentScrollPos < prevScrollPos) {
          setVisible(true);
        }

        setPrevScrollPos(currentScrollPos);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [prevScrollPos]);

    const toggleMobileNav = () => {
      const navbarCollapse = document.querySelector(".navbar-collapse");

      if (navbarCollapse) {
        navbarCollapse.classList.toggle("show");
      }

      setIsNavShowing((prev) => !prev);
    };

    return (
      <div className="main-navbar-2">
       

        <nav
          className={`navbar navbar-expand-lg ${visible ? "visible" : "hidden"}`}
          id="navbar2"
        >
          <div className="container">
            <Link
              to=""
              className="navbar-brand logo "
              onClick={() => setIsNavShowing(false)}
            >
              <img src={logo} alt="Logo" className="logo" />
            </Link>
            {/* <div className="sec3-button-nav">
          
            <Link to="/callbookings" ><button >Book Meeting</button></Link>

          
      </div> */}
            {/* <button
              ref={navButton}
              className="navbar-toggler"
              id="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button> */}
            <button
              className="nav__toggle-btn navbar-toggler bg-white hamburger"
              onClick={toggleMobileNav}
            >
              {isNavShowing ? <ImCross /> : <GiHamburgerMenu />}
            </button>

            {/* <button
              className={`navbar-toggler ${isMobileNavOpen ? 'open' : ''}`}
              type="button"
              color="white"
              onClick={toggleMobileNav}
            >
              <GiHamburgerMenu />
            </button> */}
            <div
              className={`collapse navbar-collapse ${isNavShowing ? "show" : ""}`}
            >
              {/* <ul
                className={`navbar-nav  nav-links ${
                  isNavShowing ? "show__nav" : "hide__nav"
                }`}
              >
                <li className="nav-item">
                  <Link
                    to="/home"
                    className="nav-link2"
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    Homegfgdfhgd
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/seocampaign"
                    className="nav-link2"
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/about"
                    className="nav-link2"
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                   to="/plans"
                    className="nav-link2 "
                    onClick={() => setIsNavShowing((prev) => !prev)}
                    >                  
                    Plans
                  </Link>
                
                </li>
                <li className="nav-item">
                  <Link
                  
                    to="/mission" 
                    className="nav-link2"
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    Mission
                  </Link>
                </li>
               
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link"
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    Contact
                  </Link>
                </li>
              </ul> */}
              {/* <div className="sec3-button-nav">
          
            <Link to="/callbookings">
            Book Meeting Now

            </Link>
            
        </div> */}
            </div>
          </div>
        </nav>
      </div>
    );
  };
  export default Navbar2;

  //import React, { useState, useEffect, useRef } from "react";
  // import { Link } from "react-router-dom";
  // import "./Navbar.css";
  // import homearrow from "../../assets/home-arrow.png";
  // import instaimg from "../../assets/insta-img.png";
  // import linkedimg from "../../assets/linkedin-img.png";
  // import logo from "../../assets/logo.png";

  // export default function Navbar({onLinkHover, onLinkLeave}) {
  //   const navButton = useRef(null);
  //   const linksContainerRef = useRef(null);

  //   function collapseNav() {
  //     linksContainerRef.current.classList.add("mynavbar");

  //     setTimeout(() => {
  //       linksContainerRef.current.classList.add("collapsing");
  //       linksContainerRef.current.classList.remove("collapse");
  //       navButton.current.classList.add("collapsed");
  //       linksContainerRef.current.classList.remove("show");
  //     }, 1);
  //   }

  //   return (
  //     <div>
  //       <nav className="navbar navbar-dark" id="navbar">
  //         <div className="container-fluid navbar-container">
  //           <Link className="navbar-brand" onClick={collapseNav} to="home"></Link>

  //           <button
  //             ref={navButton}
  //             className="navbar-toggler"
  //             id="navbar-toggler"
  //             type="button"
  //             data-toggle="collapse"
  //             data-target="#navbarNav"
  //             aria-controls="navbarNav"
  //             aria-expanded="false"
  //             aria-label="Toggle navigation"
  //           >
  //             <span></span>
  //             <span></span>
  //             <span></span>
  //           </button>

  //           <div
  //             className="collapse navbar-collapse"
  //             id="navbarNav"
  //             ref={linksContainerRef}
  //           >
  //             <div className="container">
  //               <div className="row">
  //                 <div className="col-lg-4 nav-items-1">
  //                   <ul className="navbar-nav ml-auto">
  //                     <li className="nav-item">
  //                       <Link
  //                          id="contact-click"
  //                          className="nav-link scroll-link"
  //                          to="/home"
  //                          onClick={collapseNav}
  //                          onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}
  //                       >
  //                       <img className="home-arrow" src={homearrow} />
  //                         Home
  //                       </Link>
  //                     </li>
  //                     <li className="nav-item">
  //                       <Link  id="contact-click"
  //                         className="nav-link scroll-link"
  //                         to="/services"
  //                         onClick={collapseNav}
  //                         onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}>
  //                         <img className="home-arrow" src={homearrow} />
  //                         Services
  //                       </Link>
  //                     </li>
  //                     <li className="nav-item">
  //                       <Link  id="contact-click"
  //                         className="nav-link scroll-link"
  //                         to="/about"
  //                         onClick={collapseNav}
  //                         onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}>
  //                         <img className="home-arrow" src={homearrow} />
  //                         About
  //                       </Link>
  //                     </li>
  //                     <li className="nav-item">
  //                       <Link
  //                         id="contact-click"
  //                         className="nav-link scroll-link"
  //                         to="/mission"
  //                         onClick={collapseNav}
  //                         onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}
  //                       >
  //                         <img className="home-arrow" src={homearrow} />
  //                         Mission
  //                       </Link>
  //                     </li>
  //                     <li className="nav-item">
  //                       <Link
  //                         id="contact-click"
  //                         className="nav-link scroll-link"
  //                         to="/contactform"
  //                         onClick={collapseNav}
  //                         onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}
  //                       >
  //                         <img className="home-arrow" src={homearrow} />
  //                         Contact
  //                       </Link>
  //                     </li>
  //                   </ul>
  //                 </div>
  //                 <div className="col-lg-5 address">
  //                   <ul className="navbar-nav-2 ml-auto">
  //                     <li className="nav-item-2">
  //                       <a className="nav-link scroll-link" href="home">
  //                         <h1>SAAA Consultants Pvt. Ltd</h1>
  //                         <p>
  //                         A wing, 11th Floor, 1104 Kanakia Wall Street,
  //                      Andheri East, Mumbai, Maharashtra 400059, India
  //                         </p>
  //                       </a>
  //                     </li>
  //                     <li className="nav-item-2">
  //                       <a className="nav-link scroll-link" href="services">
  //                         <h1>Email</h1>
  //                         <p>info@saaaconsultants.com</p>
  //                       </a>
  //                     </li>
  //                     <li className="nav-item-2">
  //                       <a className="nav-link scroll-link" href="about">
  //                         <h1>Call us at</h1>
  //                         <p>+91 7977895134</p>
  //                       </a>
  //                     </li>
  //                   </ul>
  //                 </div>
  //                 <div className="col-lg-3 links">
  //                   <div className="col header-social-media">
  //                     <h6>Connect</h6>
  //                     <div className="ss-links">
  //                       <ui className="list-styled-nav">
  //                         <li className="ss-icons">
  //                           <a href="https://www.linkedin.com/company/saaa-consultants/" target="_blank" rel="noopener noreferrer" onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}>
  //                             <img src={linkedimg} />{" "}
  //                           </a>
  //                         </li>
  //                         <li className="ss-icons">
  //                           <a href="https://www.instagram.com/saaaconsultants/" target="_blank" rel="noopener noreferrer" onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}>
  //                             <img src={instaimg} />
  //                           </a>
  //                         </li>
  //                       </ui>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </nav>
  //     </div>
  //   );
  // }
