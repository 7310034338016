import React, { useState, useEffect, useRef } from "react";
import top from "../../assets/whatsapp.png";
import uihero from "../../assets/Services/uiuxhero.svg";
import "animate.css/animate.min.css";
import Footer from "../footer/footer";
import "aos/dist/aos.css";
import useAosAndInView from "../scroll";
import "./plan.css";
import AOS from "aos";
import wireframing from "../../assets/Services/wireframing.webp";
import uxresearch from "../../assets/Services/uxresearch.webp";
import visualanduidesign from "../../assets/Services/visualandui.webp";
import responsive from "../../assets/Services/responsive.webp";
import prototype from "../../assets/Services/prototype.svg";
import AnimationComponent from "./UiuxProcessAnim";
import research from "../json/Research.json";
import update from "../json/Update.json";
import Goals from "../json/Goal.json";
import webdesign from "../json/Web Design.json";
import digitalart from "../json/Digital Art.json";
import exam from "../json/Exam.json";
import "../../component/plans/plan.css";
import "../../component/plans/responsiveplans.css";
import { Link } from "react-router-dom";

const Uiux = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const sectionRef2 = useRef(null);
  const [isVisible3, setIsVisible3] = useState(false);
  const sectionRef3 = useRef(null);
  const [isVisible4, setIsVisible4] = useState(false);
  const sectionRef4 = useRef(null);
  const [isVisible5, setIsVisible5] = useState(false);
  const sectionRef5 = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible3(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible4(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer5 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible5(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer3.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer4.observe(sectionRef4.current);
    }
    if (sectionRef5.current) {
      observer5.observe(sectionRef5.current);
    }

    // Cleanup
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer3.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer4.unobserve(sectionRef4.current);
      }
      if (sectionRef5.current) {
        observer5.unobserve(sectionRef5.current);
      }
    };
  }, []);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setHasAnimated(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust as needed
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [hasAnimated]);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const { elementRef: refan1, inView: inView1 } = useAosAndInView();
  const researchOptions = {
    loop: true,
    autoplay: true,
    animationData: research,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const updateOptions = {
    loop: true,
    autoplay: true,
    animationData: update,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const GoalOptions = {
    loop: true,
    autoplay: true,
    animationData: Goals,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const webdesignOptions = {
    loop: true,
    autoplay: true,
    animationData: webdesign,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const examOptions = {
    loop: true,
    autoplay: true,
    animationData: exam,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const digitalOptions = {
    loop: true,
    autoplay: true,
    animationData: digitalart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationData = [
    { title: "Set Design Goals", options: GoalOptions },
    { title: "User Research", options: researchOptions },
    { title: "Design concept", options: digitalOptions },
    { title: "Design Prototyping", options: webdesignOptions },
    { title: "User Testing", options: examOptions },
    { title: "Refine & Update", options: updateOptions },
  ];

  return (
    <>
      <div className="services web-plans">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="Whatsapp" />
          </a>
        </div>
        <div className="main-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 web-hero-text">
                <div className="web-hero-div">
                  <p>Ui / Ux Design</p>
                  <h1>
                    We are committed to creating flawless user interfaces that
                    put the requirements of the users first.
                  </h1>
                </div>
                <div className="service-btnhome smm-hero-btn">
                  <div className="service-actual-btn plan-slider-btn">
                    <Link to="/contact" smooth>
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5 web-hero-img">
                <img src={uihero} alt="UIUX Hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="web-timeline">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="webplan-timeline-content">
                  <h2>Process</h2>
                  <p>
                    At SAAA, we redefine digital experiences through thoughtful
                    UI/UX design. Our team meticulously crafts interfaces that
                    seamlessly blend aesthetics with functionality, ensuring
                    every click and swipe tells a story. With user-centric
                    design principles, we elevate your digital presence.
                  </p>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="uiux">
                      {animationData.map((data, index) => (
                        <AnimationComponent
                          key={index}
                          {...data}
                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="all-serv-sec">
          <div className="serv1-full-sec">
            <div
              className={`serv1-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef}
            >
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Wireframing</h1>
                  <p>
                    Wireframing, the foundational step in website and
                    application design, creates a basic structure devoid of
                    design elements. It's crucial for a clear layout, ensuring a
                    smooth user experience and saving time. Comprising simple
                    shapes and lines, wireframes represent key design elements,
                    allowing early identification and correction of potential
                    issues. At SAAA, wireframing is our secret ingredient,
                    shaping websites that look great and feel fantastic to use.
                    It's the blueprint of a digital masterpiece, laying the
                    foundation for a successful marketing campaign. If you seek
                    to enhance your online presence, wireframing is where the
                    magic begins. Let's transform your ideas into a seamless
                    digital experience!
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <img src={wireframing} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div
              className={`serv2-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef2}
            >
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <img src={uxresearch} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>UX Research</h1>
                  <p>
                    Unlocking the magic of user-friendly websites and apps, UX
                    research is the secret ingredient at SAAA. Think of it as
                    the blueprint for your digital project, understanding user
                    needs through methods like surveys and interviews. Our
                    experts gather insights to create visually appealing and
                    easy-to-use designs, ensuring a comfortable, intuitive, and
                    efficient digital space. UX research is the compass guiding
                    us to build websites and apps people love to use, making
                    every click, swipe, and tap a delightful experience. If you
                    aim to create a lasting digital masterpiece, UX research is
                    the key, and we've got the magic wand to make it happen.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div
              className={`serv1-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef3}
            >
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Visual And UI Design</h1>
                  <p>
                    At SAAA, we're passionate about creating captivating and
                    user-friendly digital experiences. In UI/UX design, Visual
                    Design and User Interface (UI) Design collaborate to craft
                    remarkable journeys. Our visual designers specialize in
                    creating aesthetically pleasing masterpieces that convey
                    your brand's personality. UI Design forms the backbone,
                    creating layouts and elements that empower users to interact
                    effortlessly. It's about guiding users on a satisfying and
                    effortless journey. Our services combine the power of Visual
                    Design to attract your audience and the finesse of UI Design
                    for a seamless experience. With us, your digital journey
                    becomes user-friendly and visually stunning. Let's transform
                    your digital presence, where beauty meets usability. Your
                    audience will thank you for it.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <img src={visualanduidesign} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div
              className={`serv2-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef4}
            >
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <img src={responsive} alt="Service 1" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Responsive Design</h1>
                  <p>
                    In the web world, "Responsive Design" is key, and here's why
                    it matters. Picture your website as a chameleon—Responsive
                    Design gives it the ability to adapt to any device, be it a
                    desktop, laptop, tablet, or smartphone. This adaptation
                    enhances user experience, boosts SEO rankings, and saves
                    costs by eliminating the need for a separate mobile site. It
                    works through flexible grids, scalable images, and CSS media
                    queries, ensuring your site looks great everywhere. In
                    essence, Responsive Design is a magic spell for your
                    website, making it shine on any device. Essential in today's
                    digital landscape, it ensures a seamless user experience,
                    improved SEO, and cost efficiency. Ready to make your
                    website a true chameleon? Contact us for Responsive Design
                    services!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div
              className={`serv1-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef5}
            >
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Prototyping</h1>
                  <p>
                    Ever tried building a house without a blueprint? Designing a
                    website or app is the same, and that's where UI/UX
                    prototyping services come in. Picture it as a rough sketch,
                    a simplified model giving a clear preview of your digital
                    product's look and functionality—a blueprint for the digital
                    realm. Prototypes are crucial for visualizing ideas, testing
                    and refining, and saving time and money by catching issues
                    early. Our process involves an initial sketch, transforming
                    into an interactive prototype for user experience
                    exploration, followed by feedback and iteration. Prototyping
                    is your project's safety net, ensuring you're on the right
                    track. At SAAA, our prototyping services turn visions into
                    digital reality. Let's bring your ideas to life!
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <img src={prototype} alt="Service 1" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn fas-chat-btn">
            <Link to="/contact" smooth>
              <span>Let's Chat</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </Link>
          </div>
        </div>
        {/* <div className="graphic-packages web-packages">
          <div className="web-packages-head">
            <h1>Ui / Ux Design Plans</h1>
            <p>
              We have a team of expert website developers who listen to your
              every single need very carefully and execute the plan to save
              time.
            </p>
          </div>
          <div className="graphic-packages">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 graphic-popular">
                  <div className="plan-top">
                    <p>Most Popular</p>
                  </div>
                  <div className="plan-item">
                    <div className="plan-outer">
                      <div className="plan-head">
                        <p>Starter</p>
                        <h3>
                          <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                          22,999*
                        </h3>
                      </div>
                      <hr />

                      <div className="plan-body">
                        <div className="plan-inner">
                          <ul>
                            <li>
                              <i className="fa-solid fa-check"></i> UI Design{" "}
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> UX Design
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> User Flow
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Website
                              Design
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Mobile App
                              Design
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Wireframes
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Responsive
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Design Tool:
                              (Figma)
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Upto 5 Pages{" "}
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> UI Kit For
                              Developers
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> 3 Revisions
                            </li>
                            <li>
                              <i className="fa-solid fa-xmark"></i> Prototype
                            </li>
                          </ul>
                        </div>

                        <div className="service-btnhome">
                          <div className="service-actual-btn plans-chat-btn">
                            <Link to="/contact" smooth>
                              <span>Let's Chat</span>
                              <i className="fa-solid fa-arrow-right arrow-services"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 graphic-popular">
                  <div className="plan-top">
                    <p>Most Popular</p>
                  </div>
                 
                  <div className="plan-item">
                    <div className="plan-outer">
                      <div className="plan-head">
                        <p>Essential</p>
                        <h3>
                          <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                          44,999*
                        </h3>
                      </div>
                      <hr />
                      <div className="plan-body">
                        <div className="plan-inner">
                          <ul>
                            <li>
                              <i className="fa-solid fa-check"></i> UI Design{" "}
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> UX Design
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> User Flow
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Website
                              Design
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Mobile App
                              Design
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Wireframes
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Responsive
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Design Tool:
                              (Figma)
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Upto 5 Pages{" "}
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> UI Kit For
                              Developers
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> 3 Revisions
                            </li>
                            <li>
                              <i className="fa-solid fa-check"></i> Prototype
                            </li>
                          </ul>
                        </div>

                        <div className="service-btnhome">
                          <div className="service-actual-btn plans-chat-btn">
                            <Link to="/contact" smooth>
                              <span>Let's Chat</span>
                              <i className="fa-solid fa-arrow-right arrow-services"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 graphic-popular">
                  <div className="plan-top">
                    <p>Most Popular</p>
                  </div>
                  <div className="plan-item">
                    <div className="plan-outer">
                      <div className="plan-head">
                        <p>Custom</p>
                        <p>Design a custom package for your business needs</p>
                      </div>
                      <hr />
                      <div className="plan-body">
                        <div className="plan-inner">
                          <p>Custom Pricing</p>
                          <ul>
                            <li>
                              <i className="fa-solid fa-check"></i>For company
                              with large transaction sizes or unique business
                              models
                            </li>
                          </ul>
                        </div>

                        <div className="service-btnhome">
                          <div className="service-actual-btn plans-chat-btn">
                            <Link to="/contact" smooth>
                              <span>Let's Chat</span>
                              <i className="fa-solid fa-arrow-right arrow-services"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default Uiux;
