import React, { useState, useEffect, useRef } from "react";
import "../casestudies/casestudies.css";
// import casevid from "../../assets/contact/form-vid.png";
import soot from "../../assets/Case Studies/sootsparshcase.webp";
import kmc from "../../assets/Case Studies/kmccase.webp";
import ziva from "../../assets/Case Studies/zivacase.webp";
import toon from "../../assets/Case Studies/tooncase.webp";
import fabriety from "../../assets/Case Studies/Fab.webp";
import namalogy from "../../assets/Case Studies/namalogycase.webp";
import lupin from "../../assets/Case Studies/lupincase.webp";
import ekvity from "../../assets/Case Studies/ekvitycase.webp";
import holisticcase from "../../assets/Case Studies/holisticcase.svg";
import Footer from "../../component/footer/footer";
import Lottie from "react-lottie";
import casehero from "../json/Case Study.json";
import { HashLink } from "react-router-hash-link";
import download from "../../assets/download.svg";
import Smmcasestudy from "../../assets/pdf/smmpdf.pdf";
import Ekvitycasestudy from "../../assets/pdf/ekvitycasestudy.pdf";
import Fabrietycasestudy from "../../assets/pdf/fabrietycasestudy.pdf";
import Holisticcasestudy from "../../assets/pdf/holisticcasestudy.pdf";
import Lupincasestudy from "../../assets/pdf/lupincasestudy.pdf";
import Namalogycasestudy from "../../assets/pdf/namalogycasestudy.pdf";
import top from "../../assets/whatsapp.png";



const CaseStudies = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const downloadPdf = () => {
    const pdfUrl = "/src/assets/Case Studies/ekvitycasestudy.pdf";

    const newWindow = window.open(pdfUrl, "_blank");
    console.log(newWindow);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: casehero,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
 
  return (
    <div className="casestudies">
       <div className="back-2-top">
        <a href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20" target="_blank">
    <img src={top} alt="Whatsapp"/>
  </a>
        </div>
        <div className="container-fluid case-sec">
        <div className="row">
          <div className="col-md-7 members-hero">
            <div className="members-hero-text">
              <p>Case Studies</p>
              <h1>
              Explore real digital success stories that start right here,
                showcasing our perfect solutions for businesses like yours.
              </h1>
            </div>
          </div>
          <div className="col-md-5 members-hero-img d-flex justify-content-center align-items-center">
            <Lottie
              options={defaultOptions}
              style={{
                height: "100%",
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",}}
            />
          </div>
        </div>
      </div>
      {/* <div className="container-fluid">
        <div className="row case-hero">
          <div className="col-md-6">
            <div className="case-hero-text">
              <p>Case Studies</p>
              <h1>
                Explore real digital success stories that start right here,
                showcasing our perfect solutions for businesses like yours.
              </h1>
            </div>
          </div>
          <div className=" col-md-6 case-hero-img">
            <Lottie
              options={defaultOptions}
              style={{
                height: "100%",
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        </div>
      </div> */}
      <div className="case">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={soot} alt="SootSparsh"/>
                </div>
                <div className="case-text">
                  <h1>Sootsparsh</h1>
                  <h2>
                    Sootsparsh is an E-commerce website selling Sareers while
                    also empowering the arisans.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Smmcasestudy} smooth target="_blank">
                        <span>Case Study</span> <img src={download} alt="Download"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={kmc} alt="KMC"/>
                </div>
                <div className="case-text">
                  <h1>KMC Hospitals</h1>
                  <h2>
                    KMC has over two decades of experience in the healthcare
                    sector, and is known for providing quality healthcare and
                    valuable experience
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Smmcasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={ziva} alt="Ziva"/>
                </div>
                <div className="case-text">
                  <h1>Ziva</h1>
                  <h2>
                    Ziva is a jewelry brand crafting elegance since 2004. They
                    provide Necklace, Rings & Bridal Sets with Real Diamonds and
                    Emeralds.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Smmcasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="case">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={holisticcase} alt="Holistic"/>
                </div>
                <div className="case-text">
                  <h1>Holistic Fitness With Rasika</h1>
                  <h2>
                    It is an online fitness platform with the combination of
                    Yoga and Power Yoga along with Zumba batches.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Holisticcasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={toon} alt="Toontouch"/>
                </div>
                <div className="case-text">
                  <h1>ToonTouch</h1>
                  <h2>
                    ToonTouch is a mother-daughter duo in the kid’s clothing
                    business of making your gifts special and personalized for
                    15 years
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Smmcasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={fabriety} alt="Fabriety"/>
                </div>
                <div className="case-text">
                  <h1>Fabriety</h1>
                  <h2>
                    Fabriety is an esteemed Indian brand specializing in
                    handmade jewelry, with a widespread presence across tier-1
                    and tier-2 cities.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Fabrietycasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="case case-last-row">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={namalogy} alt="Namalogy"/>
                </div>
                <div className="case-text">
                  <h1>Namalogy</h1>
                  <h2>
                    Namalogy is leading brand in Quantum Hypnosis Healing
                    Sessions, sought to expand their reach and boost their
                    course sales cost-effectively.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Namalogycasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={lupin} alt="Lupin"/>
                </div>
                <div className="case-text">
                  <h1>Lupin</h1>
                  <h2>
                    Lupin is an Indian multinational pharmaceutical company
                    based in Mumbai and is one of the largest generic
                    pharmaceutical companies.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Lupincasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img">
                  <img src={ekvity} alt="Ekvity"/>
                </div>
                <div className="case-text">
                  <h1>Ekvity</h1>
                  <h2>
                    Ekvity, is a very unique fusion. The multi-faceted word
                    suggests; Ek - meaning One; Vity - means Finance in the
                    native language, Hindi.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href={Ekvitycasestudy} smooth target="_blank">
                        <span>Case Study</span>
                        <img src={download} alt="Download"/>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default CaseStudies;
