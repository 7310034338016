import React, { useState, useEffect, useRef } from "react";
import { useMotionValue } from "framer-motion";
import Card from "./Card";
import missionimg from "../../assets/about/mission.webp";
import visionimg from "../../assets/about/vision.webp";

import styled from "styled-components";
import top from "../../assets/whatsapp.png";
import Footer from "../footer/footer";
import "../about/about.css";
import "../about/responsive.css";
import Aos from "aos";
import "aos/dist/aos.css";

import aboutlottie from "../json/About Us.json";
import Lottie from "react-lottie";
import saaavid from "../../assets/showreel.mp4";
import mute from "../../assets/mute.svg";
import unmute from "../../assets/unmute.svg";
import jobs from "../../assets/about/jobscard.webp";
import employment from "../../assets/about/employmentcard.webp";
import wealth from "../../assets/about/wealthcard.webp";


const Cards = [jobs, employment, wealth];

// const createMotionValues = () => {  
//   return Cards.map(() => useMotionValue(0));
// };

const About = () => {
  // const mouseXValues = createMotionValues();
  // const mouseYValues = createMotionValues();


  const [validPropertyPrefix, setValidPropertyPrefix] = useState('');
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const screenWidth = window.screen.width / 2;
  const screenHeight = window.screen.height / 2;

  useEffect(() => {
    const $elems = document.getElementsByClassName("elem");

    const elemStyle = $elems[0]?.style;

    if (elemStyle) {
      if (typeof elemStyle.webkitTransform === 'string') {
        setValidPropertyPrefix('webkitTransform');
      } else if (typeof elemStyle.MozTransform === 'string') {
        setValidPropertyPrefix('MozTransform');
      }
    }

    const handleMouseMove = (e) => {
      if (hoveredCardIndex !== null) {
        const centroX = e.clientX - screenWidth;
        const centroY = screenHeight - (e.clientY + 13);
        const degX = centroX * 0.04;
        const degY = centroY * 0.02;

        const $hoveredElem = $elems[hoveredCardIndex];
        if ($hoveredElem) {
          $hoveredElem.style[validPropertyPrefix] = `perspective(1000px) rotateY(${degX}deg) rotateX(${degY}deg)`;
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [validPropertyPrefix, screenWidth, screenHeight, hoveredCardIndex]);

  const handleCardHover = (index) => {
    setHoveredCardIndex(index);
  };

  const handleCardLeave = (e) => {
    setHoveredCardIndex(null);
    const $elem = e.target;
    if ($elem) {
      $elem.style[validPropertyPrefix] = ''; // Reset style to remove rotation
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const [videoRatio, setVideoRatio] = useState();
  const [mutedVideo, setMutedVideo] = useState(true);
  const [isVideoInView, setIsVideoInView] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: aboutlottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const observer = new IntersectionObserver((entries) => {
      const video = videoRef.current;
      if (entries[0].isIntersecting && video) {
        setIsVideoInView(true);
        video.play();
      } else if (video) {
        setIsVideoInView(false);
        video.pause();
      }
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="about-page">
        <div className="about-sec">
          <div className="back-2-top">
            <a
              href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
              target="_blank"
            >
              <img src={top}alt="Whatsapp" />
            </a>
          </div>
        </div>
      </div>
      <section>
        <div className="AbouHero">
          <div className="container-fluid pl-3">
            <div className="row justify-content-center align-items-center ">
              <div className="col-lg-6 col-sm-12">
                <div className="AboutHerodesc">
                  <p>About Us</p>
                  <h1>
                    We are diverse diaspora believing differential input will
                    produce differential output. We aim to create talent not
                    seek talent.
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 ">
                <div className="AboutHeroimg">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: aboutlottie,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    style={{ height: "100%", width: "100%" }}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-mission">
        <div className="container-fluid">
          {/* <div className="M-V-Section"> */}
          <div className="row justify-content-center align-items-center responsive-vision">
            <div className="col-lg-6 col-sm-12">
              <div className="VissionDesc">
                <h1 className="vission-heading">Our Vision</h1>
                <p className="v-par">
                  To create a harmonious ecosystem where every endeavor reflects
                  excellence, integrity, and a genuine passion for service,
                  leaving a lasting positive impact on individuals, businesses,
                  and society as a whole.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="VissionImg">
                <img src={visionimg} alt="App" />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
      <section>
        <div className="Vission-M-Section">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-sm-12">
                <div className="MissionImg">
                  <img src={missionimg} alt="App" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="MissionDesc">
                  <h1 className="mission-heading">Our Mission</h1>
                  <p className="m-para">
                    To create jobs, to create opportunities, to create wealth
                    over wider diaspora. A company that is founded on principle
                    to distribute entire profitability to the employees and none
                    to stakeholders
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className="OurLives">
          <div className="quotes-heading ">
            <h1 className="text-center">
              SAAA’s Impact{" "}
              <p className="QuoteP">
                We're the Creative Composers Turning Ideas into a
                Symphony of Triumph!
              </p>
            </h1>
          </div>
          <div className="container-fluid">
            <div className="row impact-cards">
              <div className="col-lg-4 elem" onMouseEnter={() => handleCardHover(0)} onMouseLeave={handleCardLeave}>
                <img src={jobs} alt="Jobs Card"/>
              </div>
              <div className="col-lg-4 elem" onMouseEnter={() => handleCardHover(1)} onMouseLeave={handleCardLeave}>
                <img src={employment} alt="Employment Cards"/>
              </div>
              <div className="col-lg-4 elem" onMouseEnter={() => handleCardHover(2)} onMouseLeave={handleCardLeave}>
                <img src={wealth} alt="Wealth Cards"/>
              </div>
            </div>
            {/* <div className="card-section">
              {Cards.map((content, index) => (
                <Card
                  key={index}
                  content={content}
                  mouseX={mouseXValues[index]}
                  mouseY={mouseYValues[index]}
                />
              ))}
            </div> */}
          </div>
        </div>
        </section>

        <div className="about-sec-4">
          <div className="about-end-text">
            <div className="about-vid">
              <div className="App-vid-test-about">
                <a
                  onClick={() => setMutedVideo(!mutedVideo)}
                  className={mutedVideo ? "active" : ""}
                >
                  {mutedVideo ? <img src={mute} /> : <img src={unmute} />}
                </a>

                <video
                  id="myVideo1"
                  muted={mutedVideo}
                  autoPlay={true}
                  ratio={videoRatio}
                  loop={true}
                  playsInline={true}
                  height="height99191"
                  width="100%"
                >
                  <source src={saaavid} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
   
      <Footer />
    </>
  );
};

export default About;

const Container = styled.div``;
