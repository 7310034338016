import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import "./home.css";
import projects from "../../assets/Home/projects.svg";
import clients from "../../assets/Home/clients.svg";
import sectors from "../../assets/Home/sectors.svg";
import members from "../../assets/Home/members.svg";

const Counterup = () => {
  const [counterOn, setCounterOn] = useState(false);

  const handleEnterViewport = () => {
    setCounterOn(true);
  };

  return (
    <ScrollTrigger onEnter={handleEnterViewport}>
      <div className="counter">
        <div className="container-fluid">
          <div className="row">
            <div className="clients col-sm-3">
              <div className="client-head">
                <h1>
                  {counterOn && <CountUp start={0} end={50} duration={2} />}+
                </h1>
                <hr />

              </div>
              <div className="client-text">
              <p>Delighted Clients</p>
              <img src={clients}/>
              </div>

            </div>

            <div className="clients col-sm-3">
              <div className="client-head">
                <h1>
                  {counterOn && <CountUp start={0} end={150} duration={2} />}+
                </h1>
                <hr/>
              </div>
              <div className="client-text">
              <p>Completed Projects</p>
              <img src={projects}/>
              </div>            </div>
            <div className="clients col-sm-3">
              <div className="client-head">
                <h1>
                  {counterOn && <CountUp start={0} end={10} duration={2} />}+
                </h1>
                <hr/>
              </div>
              <div className="client-text">
              <p>Sectors Served</p>
              <img src={sectors}/>
              </div>            </div>
            <div className="clients col-sm-3">
              <div className="client-head">
                <h1>
                  {counterOn && <CountUp start={0} end={20} duration={2} />}+
                </h1>
                <hr/>
              </div>
              <div className="client-text">
              <p>Team Members</p>
              <img src={members}/>
              </div>            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Counterup;
