import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider1 from "../../assets/mission/fi1.jpeg";
import Slider2 from "../../assets/mission/fi2.png";
import Slider3 from "../../assets/mission/fi3.png";
import Slider4 from "../../assets/mission/fi4.png";



export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "center",
      focusOnSelect: true,
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
   
    return (
      <div className="SlickContainer">
      <div className="container">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <style>{cssstyle}</style>
        <h2>Films</h2>
        <Slider {...settings}>
          <div className="SlickSlider" >
            <img src={Slider1} alt="Image 1" />
            <p className="SlickP film-content">Do Aankhen Barah Hath <span>-V Shantaram</span></p>
          </div>
          <div className="SlickSlider">
            <img src={Slider2} alt="Image 1"/>
            <p className="SlickP film-content">Life is Beautiful
            <span>-Roberto Benigni</span></p>
          </div>
          <div>
            <img src={Slider3} alt="Image 2"/>
            <p className="SlickP film-content">Schindler's List
            <span>-Steven Spielberg</span></p>
          </div>
          <div>
            <img src={Slider4} alt="Image 2"/>
            <p className="SlickP film-content">The Shawshank Redemption
            <span>-Frank Darabont</span></p>
          </div>
        
          
          {/* Add more slides as needed */}
        </Slider>
      </div>
      </div>
    );
  }
}

const cssstyle = `
.SlickContainer.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
  width: 400px;
}
// p{
//   display: none; // Hide text by default

// }
// .center .slick-center p {
//   color: #e67e22;
//   opacity: 1;
//   -ms-transform: scale(1.08);
//   transform: scale(1.08);
//   display: block; // Show text when centered
//   }
.SlickP span{
  color:#BEBEBE;
  font-weight: 500;
  // position:absolute;
  font-size:0.6rem;
  // left:14%;
  // top:15%
  text-align:center
}
 .SlickP {
    margin-bottom:4rem;
    margin-top:-3rem;
   font-family: Proxima Nova;
   font-weight: 600;
   font-size: 0.8rem;
    line-height: 100px;
    // margin: 10px;
    // padding: 2%;
    position: relative;
    text-align: center;
    display: none;
}
// .slick-next:before, .slick-prev:before {
//     color: #000;
// }
.center .slick-center  .SlickP {
    color: #0F0F0F;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
    display: block;
}
.center .SlickP {
    transition: all .10s ease;
}
`;
