import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Lottie from "react-lottie";
import homeslider2 from "../json/Teamwork.json";
import homeslider3 from "../json/Successful Marketer.json";
import homeslider1 from "../json/Landing Page.json";
import nextArrowImage from "./img/iconleftarrow.svg";
import prevArrowImage from "./img/iconarrow.svg";
import { HashLink } from "react-router-hash-link";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`MyArrow1 ${className}`}
      style={{
        ...style,
        rotate: "90deg",
        left: "20%",
        top: "22rem",
        backgroundImage: `url(${nextArrowImage})`,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`MyArrow2 ${className}`}
      style={{
        ...style,
        top: "22rem",
        left: "0",
        rotate: "90deg",
        backgroundImage: `url(${prevArrowImage})`,
      }}
      onClick={onClick}
    />
  );
}

function AsNavFor() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  const sliderSettings1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    dots: false,
    dotsClass: "slick-dots slick-thumb SlickDots",
    asNavFor: nav2,
    ref: (slider) => (sliderRef1 = slider),
    autoplay: false, // Add autoplay
    autoplaySpeed: 4000, // Set autoplay speed in milliseconds
    arrow: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderSettings2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav1,
    ref: (slider) => (sliderRef2 = slider),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <div className="slider-parent home-carousel">
        <div className="container-fluid SliderContainer">
          <div className="row d-flex justify-content-center align-items-center secoundNavSlider">
            <div className="col-lg-6 col-md-12 col-sm-12 align-items-center sliderColumn">
              <Slider {...sliderSettings1}>
                <div className="BooksPar-1">
                  <h1>Unleashing Imagination, Crafting Excellence – Where
                    Creativity Finds its Canvas, and Ideas Take Flight!</h1>
                  <div className="service-btnhome ">
                    <div className="service-actual-btn home-slider-btn">
                      <HashLink to="/contact" smooth>
                        <span>Know More</span>
                      </HashLink>
                    </div>
                  </div>
                </div>
                <div className="BooksPar-1">
                  <h1>   Youthful brilliance, boundless creativity! Dive into a world
                    where every idea is a masterpiece. </h1>{" "}
                  <div className="service-btnhome ">
                    <div className="service-actual-btn home-slider-btn">
                      <HashLink to="/contact" smooth>
                        <span>Know More</span>
                      </HashLink>
                    </div>
                  </div>
                </div>
                <div className="BooksPar-1">
                  <h1> Elevate Your Vision, Empower Your Team: We're more than a
                    partner; we're the seamless extension of your success story.</h1>
                  <div className="service-btnhome ">
                    <div className="service-actual-btn home-slider-btn">
                      <HashLink to="/contact" smooth>
                        <span>Know More</span>
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
              </Slider>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 align-items-center SliderimgCol">
              <Slider {...sliderSettings2}>
                <div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: homeslider1,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </div>
                <div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: homeslider2,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </div>
                <div className="last-home-slider">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: homeslider3,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AsNavFor;
