import React, { useState, useEffect, useRef } from "react";

import top from "../../assets/whatsapp.png";
import "animate.css/animate.min.css";
import Footer from "../footer/footer";
import AOS from "aos";
import "aos/dist/aos.css";
// import useAosAndInView from "../scroll";

import "./plan.css";
import icon1 from "../../component/json/Search.json";
import icon2 from "../../component/json/Schedule.json";
import icon3 from "../../component/json/Development.json";
import icon4 from "../../component/json/SEO Monitoring.json";
import icon5 from "../../component/json/Puzzle.json";
import serv1 from "../../assets/Services/serv1.webp";
import paid from "../../assets/Services/paidcampaign.webp";
import seo from "../../assets/Services/seo.webp";
import youtube from "../../assets/Services/youtube.webp";
import content from "../../assets/Services/content.webp";
import smmhero from "../../assets/Services/smmhero.svg";
import { useSpring, animated } from "react-spring";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";


const icons = [icon2, icon3, icon4, icon5, icon1];

const SmmPlans = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const sectionRef2 = useRef(null);
  const [isVisible3, setIsVisible3] = useState(false);
  const sectionRef3 = useRef(null);
  const [isVisible4, setIsVisible4] = useState(false);
  const sectionRef4 = useRef(null);
  const [isVisible5, setIsVisible5] = useState(false);
  const sectionRef5 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible3(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible4(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer5 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible5(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer3.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer4.observe(sectionRef4.current);
    }
    if (sectionRef5.current) {
      observer5.observe(sectionRef5.current);
    }

    // Cleanup
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer3.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer4.unobserve(sectionRef4.current);
      }
      if (sectionRef5.current) {
        observer5.unobserve(sectionRef5.current);
      }
    };
  }, []);

  const props = useSpring({
    // opacity: animation ? 1 : 0,
    // transform: animation ? "scale(1)" : "scale(0)",
    config: { duration: 200 },
  });

  const spacing = 20;
  const startAnimation = () => {
    setAnimation(true);
  };

  const heading = ["Plan", "Execute", "Monitor", "Optimize", "Research"];
  const circleStyle = {
    position: "relative",
    border: "2px dashed #fbb13c",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "auto",
  };
  const iconStyle = {
    position: "absolute",
    height: "30px",
    width: "30px",
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);



  return (
    <>
      <div className="services web-plans">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="whatsapp" />
          </a>
        </div>
        <div className="main-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 web-hero-text">
                <div className="web-hero-div">
                  <p>Digital Marketing</p>
                  <h1>
                    With our extensive knowledge and experience in the digital
                    world, we can help your brand stand out and reach your
                    target audience with precision and impact.
                  </h1>
                </div>
                <div className="service-btnhome smm-hero-btn">
                  <div className="service-actual-btn plan-slider-btn">
                    <Link to="/contact" smooth>
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-5 web-hero-img">
                <img src={smmhero} alt="SMM Hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="web-timeline">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="webplan-timeline-content-smm">
                  <h2>Process</h2>
                  <p>
                    We are masters in the art of digital storytelling. Every
                    campaign, every click, and every post is a carefully crafted
                    narrative designed to elevate your brand. We dive deep into
                    the digital realm, paying meticulous attention to detail, to
                    turn your online presence into a compelling and
                    results-driven success story."
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="solution-circle">
                  <div style={circleStyle} className="solution-circle-icon">
                    {icons.map((icon, index) => (
                      <animated.div
                        key={index}
                        style={{
                          ...props,
                          delay: index * 200,
                          ...iconStyle,
                          top: `${50 -
                            8 +
                            50 *
                            Math.sin((index * (2 * Math.PI)) / icons.length)
                            }%`, // Adjust the radius as needed
                          left: `${50 -
                            6 +
                            50 *
                            Math.cos((index * (2 * Math.PI)) / icons.length)
                            }%`,
                        }}
                        onMouseEnter={startAnimation}
                      >
                        <div
                          className="icon-parent-circle"
                          style={{
                            width: "60px",
                            borderRadius: "50%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            // borderRight:
                            //   index < icons.length - 1
                            //     ? `${spacing}px solid transparent`
                            //     : "none",
                            boxSizing: "border-box",
                          }}
                        >
                          <Lottie
                            options={{
                              autoplay: true,
                              loop: true,
                              animationData: icon,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            style={{ backgroundColor: "#fff" }}
                          />
                          {/* <img
                              src={icon}
                              alt={`icon-${index}`}
                              style={{ backgroundColor: "#fff"}}
                            /> */}
                          <h4 className="icon-text">{heading[index]}</h4>
                        </div>
                      </animated.div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="all-serv-sec">
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef}>
              <div
                className={`serv1-tex-div fade-in-section ${isVisible ? "is-visible" : ""
                  }`}
              >
                <div className="serv1-text">
                  <h1>Social Media Marketing</h1>
                  <p>
                    SAAA Consultants specializes in Social Media Marketing services tailored to elevate your brand's online presence. Our strategic approach ensures your business stands out on platforms like Facebook, Instagram, Twitter, and LinkedIn. With engaging content, targeted campaigns, and community management, we foster meaningful connections and drive results. Trust us to keep your brand at the forefront of the digital landscape, making social media a powerful asset for your business. Let's transform your online presence together!
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${isVisible ? "is-visible" : ""
                  }`}
              >
                <img src={serv1} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={`serv2-sec `} ref={sectionRef2}>
              <div
                className={`serv2-tex-img fade-in-section ${isVisible2 ? "is-visible" : ""
                  }`}
              >
                <img src={paid} alt="Service 1" className="lozad" />
              </div>
              <div
                className={`serv2-tex-div other-element ${isVisible2 ? "is-visible" : ""
                  }`}
              >
                <div className="serv2-text">
                  <h1>Paid Campaigns</h1>
                  <p>
                    At SAAA Consultants, we empower brands with the dynamic force of paid media, leveraging platforms like Google Ads and Facebook Ads for precise audience targeting. Our approach ensures measurable results, immediate visibility, and swift conversions, making paid media an invaluable asset. In today's competitive landscape, embracing paid media is imperative for online presence and revenue growth. Our tailored campaigns, strategic planning, and budget optimization guarantee ROI. Captivating ad creatives, continuous monitoring, A/B testing, and adaptive strategies drive higher click-through rates. With SAAA Consultants, unlock the full potential of paid marketing for remarkable growth and ROI. Contact us today for a journey toward digital marketing excellence
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef3}>
              <div
                className={`serv1-tex-div fade-in-section ${isVisible3 ? "is-visible" : ""
                  }`}
              >
                <div className="serv1-text">
                  <h1>Search Engine Optimization</h1>
                  <p>
                  If you rely heavily on the Internet for your marketing efforts, it's crucial to ensure that your website is optimized for search engines. Search Engine Optimization (SEO) plays a vital role in securing a steady and sustainable stream of web traffic over the long run. Undertaking SEO by yourself can be a challenging task because it's difficult to discern which strategies are effective and which are not. This is precisely choosing the services of a professional SEO Agency, such as ours at SAAA Consultants Pvt Ltd, is highly recommended. Our team of SEO experts is adept at fine-tuning your website to align with the requirements of major search engines like Google, Bing, ultimately leading to improved rankings on the internet. We focus on various key areas within our packages to ensure that you receive the best possible returns on your investment.


                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${isVisible3 ? "is-visible" : ""
                  }`}
              >
                <img src={seo} alt="Service 1" className="lozad" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={`serv2-sec`} ref={sectionRef4}>
              <div
                className={`serv2-tex-img fade-in-section ${isVisible4 ? "is-visible" : ""
                  }`}
              >
                <img src={youtube} alt="Service 1" className="lozad" />
              </div>
              <div
                className={`serv2-tex-div other-element ${isVisible4 ? "is-visible" : ""
                  }`}
              >
                <div className="serv2-text">
                  <h1>Youtube Marketing</h1>
                  <p>
                    YouTube has evolved into a global platform where video content can engage, educate, and inspire millions. At SAAA Consultants, we recognize the immense potential of YouTube marketing in today's digital era. Beyond mere videos, we create engaging, high-quality content—from product demos to brand stories. Our approach includes optimization, targeting, and analytics for maximum impact. We ensure the right audience discovers your content by staying one step ahead of YouTube's algorithms. Expect heightened brand visibility, increased engagement, and a significant ROI. Let SAAA Consultants be your YouTube marketing partner, guiding your brand to success in the world's second-largest search engine. Contact us today to unleash YouTube's full potential for your brand story.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div className={`serv1-sec`} ref={sectionRef5}>
              <div
                className={`serv1-tex-div fade-in-section ${isVisible5 ? "is-visible" : ""
                  }`}
              >
                <div className="serv1-text">
                  <h1>Content Writing</h1>
                  <p>
                  In today's digital landscape, content is king, and content marketing reigns supreme. At SAAA Consultants, we understand the pivotal role content plays in shaping your brand's success. Our seasoned team crafts compelling narratives tailored to your business goals, ensuring every piece resonates with your audience. We go beyond mere posts, offering comprehensive solutions that drive measurable results. By leveraging data-driven insights, we refine strategies for maximum impact, staying ahead of industry trends. Expect increased visibility, higher engagement, and improved ROI. Let SAAA Consultants be your content partner, shaping a potent online presence for your business success.


                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${isVisible5 ? "is-visible" : ""
                  }`}
              >
                <img src={content} alt="Service 1" className="lozad" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn fas-chat-btn">
            <Link to="/contact" smooth>
              <span>Let's Chat</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </Link>
          </div>
        </div>
      
        {/* <div className="graphic-packages">
          <div className="web-packages-head">
            <h1>Social Media Marketing Plans</h1>
            <p>
              We have a team of expert website developers who listen to your
              every single need very carefully and execute the plan to save
              time.
            </p>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Starter</p>
                      <h3>
                        <i className="fa-solid fa-indian-rupee-sign"></i> 19,999*
                      </h3>
                    </div>
                    <hr />

                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>Instagram</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> Competitor
                            analysis (3 Competitors)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Creation of
                            Instagram Profile
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram
                            Profile Hygiene
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram
                            Profile Optimisation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Facebook Page
                            Creation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Facebook Page
                            Optimisation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Meta Content
                            Calendar & Strategy
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Meta Post
                            Creation & Posting - 12 Posts (Images, Carousels &
                            Reels)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Meta Story
                            Creation & Posting (1 Story/week)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Responding to
                            Comments of Meta
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram
                            Analytics & Monitoring
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Post
                            Boosting - 4 Post/Month (250 ₹ / Post)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Ad
                            Setup (if required)*
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Ad
                            Creative Designing (if required) *
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Ad
                            Monitoring & Engagement*
                          </li>
                        </ul>
                      </div>
                      <div className="plan-inner">
                        <p>LinkedIn</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-xmark"></i> LinkedIn Profile
                            Creation
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> LinkedIn Profile
                            Optimisation
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> Engagement with
                            Network on LinkedIn
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> Writing &
                            Sharing Blogs & Articles of recent niche news & trend
                            on LinkedIn
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> Thought
                            Leadership & Personal Branding Strategy on LinkedIn
                          </li>
                          <li>
                            <i className="fa-solid fa-xmark"></i> Analytics &
                            Performance Tracking of LinkedIn Account
                          </li>
                        </ul>
                      </div>

                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
               
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Essential</p>
                      <h3>
                        <i className="fa-solid fa-indian-rupee-sign"></i> 29,999*
                      </h3>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>Instagram</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> Competitor
                            analysis (3 Competitors)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Creation of
                            Instagram Profile
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram
                            Profile Hygiene
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram
                            Profile Optimisation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Facebook Page
                            Creation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Facebook Page
                            Optimisation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Meta Content
                            Calendar & Strategy
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Meta Post
                            Creation & Posting - 12 Posts (Images, Carousels &
                            Reels)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Meta Story
                            Creation & Posting (1 Story/week)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Responding to
                            Comments of Meta
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram
                            Analytics & Monitoring
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Post
                            Boosting - 4 Post/Month (250 ₹ / Post)
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Ad
                            Setup (if required)*
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Ad
                            Creative Designing (if required) *
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Instagram Ad
                            Monitoring & Engagement*
                          </li>
                        </ul>
                      </div>
                      <div className="plan-inner">
                        <p>LinkedIn</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i> LinkedIn Profile
                            Creation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> LinkedIn Profile
                            Optimisation
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Engagement with
                            Network on LinkedIn
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Writing &
                            Sharing Blogs & Articles of recent niche news & trend
                            on LinkedIn
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Thought
                            Leadership & Personal Branding Strategy on LinkedIn
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i> Analytics &
                            Performance Tracking of LinkedIn Account
                          </li>
                        </ul>
                      </div>

                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Custom</p>
                      <p>Design a custom package for your business needs</p>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>Custom Pricing</p>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i>For company with
                            large transaction sizes or unique business models
                          </li>
                        </ul>
                      </div>

                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="graphic-packages">
          <div className="web-packages-head">
            <h1>Search Engine Optimization Plans</h1>
            <p>
              We have a team of expert website developers who listen to your
              every single need very carefully and execute the plan to save
              time.
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Starter</p>
                      <h3><i className="fa-solid fa-indian-rupee-sign"></i> 9,999*</h3>
                    </div>
                    <hr />

                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>On Page</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i> Keyword research</li>
                          <li><i className="fa-solid fa-check"></i> Competitor Analysis</li>
                          <li><i className="fa-solid fa-check"></i> Meta title & Meta description</li>
                          <li><i className="fa-solid fa-check"></i> Content optimization</li>
                          <li><i className="fa-solid fa-check"></i> Internal Linking</li>
                          <li><i className="fa-solid fa-xmark"></i> URL optimization</li>
                          <li><i className="fa-solid fa-xmark"></i>Implementation of sitemap.xml and robots.txt</li>
                          <li><i className="fa-solid fa-xmark"></i> Implementation of GA 4 code and GSC code</li>
                          <li><i className="fa-solid fa-xmark"></i>Core web vitals implementation/suggestions</li>
                          <li><i className="fa-solid fa-xmark"></i> Rich Snippet Implementation</li>


                        </ul>
                        <p>Off Page</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i> 2 Blogs Submissions</li>
                          <li><i className="fa-solid fa-check"></i> 2 Articles Submissions</li>
                          <li><i className="fa-solid fa-check"></i> Bookmark and Search Engine Submission</li>
                          <li><i className="fa-solid fa-check"></i> Local Directory / Classifieds Submission</li>
                          <li><i className="fa-solid fa-check"></i> QNA - Quora / Yahoo Answers</li>
                          <li><i className="fa-solid fa-check"></i> PR Submissions</li>
                          <li><i className="fa-solid fa-xmark"></i> Info graphic Submission</li>
                          <li><i className="fa-solid fa-xmark"></i> Forum Link bulding</li>
                          <li><i className="fa-solid fa-xmark"></i> PPT Submissions</li>
                          <li><i className="fa-solid fa-xmark"></i> Image Submissions with Link Placement</li>
                          <li><i className="fa-solid fa-xmark"></i> Video Submissions</li>
                          <li><i className="fa-solid fa-xmark"></i> Guest Blogging (On Demand)</li>


                        </ul>
                        <p>Analysis</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i>Traffic analysis from Organic Keywords</li>
                          <li><i className="fa-solid fa-check"></i> Traffic analysis from all the keywords</li>
                          <li><i className="fa-solid fa-xmark"></i>Conversion analysis</li>
                        </ul>
                        <p>Reporting</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i>Keyword Performance & Organic Traffic Report</li>
                          <li><i className="fa-solid fa-check"></i>Google Analytics Report and suggestion</li>
                          <li><i className="fa-solid fa-check"></i> On-Page Suggestions</li>
                          <li><i className="fa-solid fa-check"></i>Google Search Console (GSC)</li>
                          <li><i className="fa-solid fa-check"></i> Technical Audit Report</li>
                          <li><i className="fa-solid fa-check"></i> Backlinks Quality Report</li>


                        </ul>
                      </div>

                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>

                <div className="plan-item">

                  <div className="plan-outer">

                    <div className="plan-head">
                      <p>Essential</p>
                      <h3><i className="fa-solid fa-indian-rupee-sign"></i> 19,999*</h3>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>On Page</p>

                        <ul>
                          <li><i className="fa-solid fa-check"></i> Keyword research</li>
                          <li><i className="fa-solid fa-check"></i> Competitor Analysis</li>
                          <li><i className="fa-solid fa-check"></i> Meta title & Meta description</li>
                          <li><i className="fa-solid fa-check"></i> Content optimization</li>
                          <li><i className="fa-solid fa-check"></i> Internal Linking</li>
                          <li><i className="fa-solid fa-check"></i> URL optimization</li>
                          <li><i className="fa-solid fa-check"></i>Implementation of sitemap.xml and robots.txt</li>
                          <li><i className="fa-solid fa-check"></i> Implementation of GA 4 code and GSC code</li>
                          <li><i className="fa-solid fa-check"></i>Core web vitals implementation/suggestions</li>
                          <li><i className="fa-solid fa-check"></i> Rich Snippet Implementation</li>

                        </ul>
                        <p>Off Page</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i> 2 Blogs Submissions</li>
                          <li><i className="fa-solid fa-check"></i> 2 Articles Submissions</li>
                          <li><i className="fa-solid fa-check"></i> Bookmark and Search Engine Submission</li>
                          <li><i className="fa-solid fa-check"></i> Local Directory / Classifieds Submission</li>
                          <li><i className="fa-solid fa-check"></i> QNA - Quora / Yahoo Answers</li>
                          <li><i className="fa-solid fa-check"></i> PR Submissions</li>
                          <li><i className="fa-solid fa-check"></i> Info graphic Submission</li>
                          <li><i className="fa-solid fa-check"></i> Forum Link bulding</li>
                          <li><i className="fa-solid fa-check"></i> PPT Submissions</li>
                          <li><i className="fa-solid fa-check"></i> Image Submissions with Link Placement</li>
                          <li><i className="fa-solid fa-check"></i> Video Submissions</li>
                          <li><i className="fa-solid fa-check"></i> Guest Blogging (On Demand)</li>


                        </ul>
                        <p>Analysis</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i>Traffic analysis from Organic Keywords</li>
                          <li><i className="fa-solid fa-check"></i> Traffic analysis from all the keywords</li>
                          <li><i className="fa-solid fa-check"></i>Conversion analysis</li>
                        </ul>
                        <p>Reporting</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i>Keyword Performance & Organic Traffic Report</li>
                          <li><i className="fa-solid fa-check"></i>Google Analytics Report and suggestion</li>
                          <li><i className="fa-solid fa-check"></i> On-Page Suggestions</li>
                          <li><i className="fa-solid fa-check"></i>Google Search Console (GSC)</li>
                          <li><i className="fa-solid fa-check"></i> Technical Audit Report</li>
                          <li><i className="fa-solid fa-check"></i> Backlinks Quality Report</li>


                        </ul>
                      </div>

                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 graphic-popular">
                <div className="plan-top">
                  <p>Most Popular</p>
                </div>
                <div className="plan-item">
                  <div className="plan-outer">
                    <div className="plan-head">
                      <p>Custom</p>
                      <p>Design a custom package for your business needs</p>
                    </div>
                    <hr />
                    <div className="plan-body">
                      <div className="plan-inner">
                        <p>Custom Pricing</p>
                        <ul>
                          <li><i className="fa-solid fa-check"></i>For company with large transaction sizes or unique business models</li>



                        </ul>
                      </div>

                      <div className="service-btnhome">
                        <div className="service-actual-btn plans-chat-btn">
                          <Link to="/contact" smooth>
                            <span>Let's Chat</span>
                            <i className="fa-solid fa-arrow-right arrow-services"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default SmmPlans;
