import React, { useState, useEffect } from "react";
import "../src/contact.css";
import top from "../src/assets/whatsapp.png";
import Footer from "../src/component/footer/footer";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import contacthero from "./component/json/Mail Us.json";
import Lottie from "react-lottie";
import plane from "../src/assets/Home/SAAA_Plane Animation 02.mp4.lottie.json";


const ContactForm = () => {
  const [setIsSuccess] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const containerRef6 = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef6.current) {
      observer.observe(containerRef6.current);
    }

    return () => {
      if (containerRef6.current) {
        observer.unobserve(containerRef6.current);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const form = useRef();

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_fm44h2v",
        "template_kd35s4h",
        form.current,
        "ZvSouAhFGg-pT7wf8"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSuccess(true);
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
    // form.target.reset();
  };

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    number: "",
    detail: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateFormData = (data) => {
    let errors = {};
    if (!data.name || !data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.company || !data.company.trim()) {
      errors.company = "Company Name is required";
    }
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is not valid";
    }
    if (!data.number || !/^\d{10}$/.test(data.number)) {
      errors.number = "Phone number must be exactly 10 digits";
    }
    if (!data.detail || !data.detail.trim()) {
      errors.detail = "Message is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateFormData(formData);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      // Form submission logic goes here
      // console.log('Form submitted:', formData);
      alert("Form Submitted Successfully!!! Thank You.");
      sendEmail();
    } else {
      setErrors(errors);
      if (errors.name) {
        alert("Name is required");
      } else if (errors.company) {
        alert("Company Name is required");
      } else if (errors.email) {
        alert("Email is required");
      } else if (errors.number) {
        if (errors.number) {
          alert("Mobile Number is required");
        } else {
          alert("Phone number must be exactly 10 digits");
        }
      } else if (errors.detail) {
        alert("Message is required");
      } else {
        alert("All field required");
      }
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: contacthero,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="contact">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="Whatsapp"/>
          </a>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="form-hero">
              <div className="form-hero-text">
                <p>Contact Us</p>
                <h1>
                  Let's imagine, work together, and create side by side. We're
                  excited to chat with you.
                </h1>
              </div>
              <div className="form-hero-img">
                <Lottie
                  options={defaultOptions}
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="rocket-up-contact" ref={containerRef6}>
            {isVisible && (
              <Lottie
                options={{
                  autoplay: true,
                  loop: false,
                  animationData: plane,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                style={{ height: "20%", width: "30%", margin: 0 }}
              />
            )}
          </div>
          <form ref={form} className="form-full" onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Full Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="company">Company Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      onChange={handleChange}
                      name="company"
                      placeholder="type here"
                      style={{
                        color: "#fbb13c",
                        fontWeight: 600,
                        fontSize: "1.2rem",
                        marginTop: "20px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="email">Email address*</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      onChange={handleChange}
                      name="email"
                      placeholder="name@example"
                    />
                    <small className="form-text text-muted email-privacy">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="number"
                      onChange={handleChange}
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="detail">Brief Details*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="detail"
                      onChange={handleChange}
                      name="detail"
                      placeholder="Eg. Website Design"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="new-service-btn">
              <button>Submit</button>
            </div>
          </form>
          
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default ContactForm;
