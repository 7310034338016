import React, { useState, useEffect, useRef } from "react";
import "../seocampaign/seocampaign.css";
import doodle from "../../assets/SeoCampaign/doodle.png.png";
import stat1 from "../../assets/SeoCampaign/stats1.jpeg";
import stat2 from "../../assets/SeoCampaign/stats2.jpeg";
import stat3 from "../../assets/SeoCampaign/stats3.png";
import google from "../../assets/SeoCampaign/google.svg";
import stocks from "../../assets/SeoCampaign/stocks.svg";
import bag from "../../assets/SeoCampaign/bag.svg";
import mic from "../../assets/SeoCampaign/mic.svg";
import desktop from "../../assets/SeoCampaign/desktop.svg";
import dollar from "../../assets/SeoCampaign/dollar.svg";
import check from "../../assets/SeoCampaign/check.svg";
import yellowrect from "../../assets/SeoCampaign/yellow-rect.png";
import mystery from "../../assets/SeoCampaign/mystery.png";
import Counterup from "../home/Counterup";
import one from "../../assets/SeoCampaign/1.png";
import two from "../../assets/SeoCampaign/2.png";
import three from "../../assets/SeoCampaign/3.png";
import four from "../../assets/SeoCampaign/4.png";
import five from "../../assets/SeoCampaign/5.png";
import grid1 from "../../assets/Home/grid1hover.png";
import grid2 from "../../assets/Home/grid2hover.png";
import grid3 from "../../assets/Home/grid3hover.png";
import grid4 from "../../assets/Home/grid4hover.png";
import grid5 from "../../assets/Home/grid5hover.png";
import grid6 from "../../assets/Home/grid6hover.png";
import grid7 from "../../assets/Home/grid7hover.png";
import grid8 from "../../assets/Home/grid8hover.png";
import grid9 from "../../assets/Home/grid9hover.png";
import grid10 from "../../assets/Home/grid10hover.png";
import grid11 from "../../assets/Home/grid11hover.png";
import popimg from "../../assets/SeoCampaign/popimg.png";
import Marquee from "react-fast-marquee";
import overwork from "../../assets/SeoCampaign/Overwork.png";
import overpromise from "../../assets/SeoCampaign/Overpromise.png";
import shortcut from "../../assets/SeoCampaign/Shortcut.png";
import whyimage from "../../assets/SeoCampaign/whyimage.png";
import Navbar2 from "../Navbar/Navbar2";
import useAosAndInView from "../scroll";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
// import emailjs from ""
import emailjs from "@emailjs/browser";

export default function SEOCampaign() {
  const [validated, setValidated] = useState(false);
  const form = useRef();
 

const handleSubmit = (event) => {
  const formElement = event.currentTarget;
  const nameInput = formElement.querySelector('[name="name"]');
  const emailInput = formElement.querySelector('[name="email"]');
  const phoneNumberInput = formElement.querySelector('[name="number"]');
  

  if (
    formElement.checkValidity() === false ||
    !emailInput.value.includes('@') ||
    emailInput.value.trim() === '' ||
    phoneNumberInput.value.length !== 10 ||
    phoneNumberInput.value.trim() === '' ||
    nameInput.value.trim() === ''
  ) {
    event.preventDefault();
    event.stopPropagation();

    // Display individual alerts for different errors
    if (!emailInput.value.includes('@') || emailInput.value.trim() === '') {
      alert('Please enter a valid email address.');
    } else if (phoneNumberInput.value.length !== 10 || phoneNumberInput.value.trim() === '') {
      alert('Phone number must be exactly 10 digits.');
    } else if (nameInput.value.trim() === '') {
      alert('Please enter your name.');
    }


    // Manually set form validity to false
    formElement.reportValidity();

    // Set validated to false
    setValidated(false);
  } else {

    // Form is valid, proceed with email sending
    alert('Thank you we  will get back to you')
    sendEmail(event);
    
  }
};

const sendEmail = (e) => {
  e.preventDefault();
  const formElement = form.current;
  const nameInput = formElement.querySelector('[name="name"]');
  const emailInput = formElement.querySelector('[name="email"]');
  const phoneNumberInput = formElement.querySelector('[name="number"]');

  if (
    phoneNumberInput.value.length !== 10 ||
    phoneNumberInput.value.trim() === '' ||
    !emailInput.value.includes('@') ||
    emailInput.value.trim() === '' ||
    nameInput.value.trim() === ''
  ) {
    // Additional validation for email, phone number, and name
    alert('Validation failed. Please check your inputs.');
    setValidated(false);
    return;
  }

  // Form is valid, proceed with email sending
  emailjs
    .sendForm(
      "service_4s1sbuj",
      "template_nwyisas",
      formElement,
      "Pv2CPb8JpwPTzAoq0"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log("We will get back to you");
        // Set validated to true after successful email sending
        setValidated(true);
        // Close the popup

        closePopup();
      },
      (error) => {
        console.log(error.text);
        // Set validated to false if there's an error in email sending
        setValidated(false);
      }
    );
};

  const [scrollSpeed, setScrollSpeed] = useState(10);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      setScrollSpeed(scrollY * 0.01);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };
  const closePopup = () => {
    setPop(false);
  };

  return (
    <>
   
    <div className="seocampaign">
    
      <Navbar2 />

      {popup ? (
              <div className="popup-parent">
        <div className="popup">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="close-btn" onClick={closePopup}>
                  <i className="fa-solid fa-xmark"></i>{" "}
                </h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img className="van-img-form" src={popimg} />
              </div>
              <div className="col-md-6">
                <div className="popup-header">
                  <form
                    className={`pop-form ${popup ? "show" : ""}`}
                    ref={form}
                    noValidate validated={validated}
                    onSubmit={(event) => {
                      handleSubmit(event);
                      sendEmail(event);
                    }}
                  >
                    <div className="form-group">
                      <label htmlFor="name">Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email address*</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="name@example"
                        
                      />
                      <small className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number*</label>
                      <input
                        type="number"
                        className="form-control"
                        name="number"
                        placeholder="Mobile Number"

                        required="required"
                      />
                    </div>
                    <button type="submit" className="btn-submit" >
                      Submit
                      <i></i>
                    </button>

                  </form>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    ) : (
      ""
    )}
      
    
      <div className="seocampaign-sec">
        <div className="seocampaign-head">
          <img src={doodle} />
          <div className="seocampaign-text">
            <h1>
              We Help Businesses Increase Their
              <b>
                <u> ORGANIC TRAFFIC By 2x </u>
              </b>
              & Achieve
              <b>
                <u> 5x ROAS On PAID ADVERTISEMENTS. </u>
              </b>
            </h1>
            <p>
              Using our proven framework and strategies that are purely ethical.
            </p>
            <p>
              Get{" "}
              <b>
                <u> 200+ appointments </u>
              </b>{" "}
              booked every month with our SEO Strategies and ranking in the top
              5 searches (within 3 months Guaranteed!){" "}
            </p>
            <div className="sec3-button">
              <Link>
                <button onClick={handleClickOpen}>Schedule Now </button>
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="seocampaign-sec2">
        <div className="container">
          <div className="row">
            <p>
              About 1 in 12 businesses close every year. One of the main reasons
              why most of these businesses lose the battle is that they fail to
              update their businesses with changing trends. The most crucial
              ones being
            </p>
            <ul>
              <li>👉 Failing To Take Their Business Online</li>
              <li>👉 Not Establishing Online Brand Presence</li>

              <li>👉 Not Growing Social Reach</li>

              <li>👉 Not Leveraging Paid Advertisements</li>

              <li>👉 Not Tracking Competitors</li>
            </ul>
            <p>
              We can help you overcome these & many other problems, as we have
              helped many others & continue to help many more.
            </p>
          </div>
        </div>
      </div>
      <div className="seocampaign-sec3">
        <div className="container border-sec">
          <div className="seocampaign-sec3-head">
            <h1>RECENT PERFORMANCE HIGHLIGHTS.</h1>
          </div>
          <div className="row margin-sec">
            <ol>
              <li>
                <div className="check">
                  <img src={one} />
                </div>
                <div className="list-inner3">
                  <span> High ranking of over 1000+</span>&nbsp; keywords for
                  our clients starting from scratch
                </div>
              </li>
              <li>
                <div className="check">
                  <img src={two} />
                </div>
                <div className="list-inner3">
                  <span> 381 Calls</span>&nbsp;in a month were made through
                  Google Business Profile.<span>&nbsp;Growth of 27%</span>
                  &nbsp;from the Previous Month.
                </div>
              </li>
              <li>
                <div className="check">
                  <img src={three} />
                </div>
                <div className="list-inner3">
                  <span> 189 People made use of Google Maps</span>&nbsp;to visit
                  the location with around&nbsp;
                  <span>
                    21 % increase in the stats from previous month & more than
                    57.5 % Increase from previous year through local SEO.
                  </span>
                </div>
              </li>
              <li>
                <div className="check">
                  <img src={four} />
                </div>
                <div className="list-inner3">
                  Within 1.5 months of implementing the SEO strategy, the
                  client's website has experienced a&nbsp;
                  <span>70% increase in organic traffic.</span>
                </div>
              </li>
              <li>
                <div className="check">
                  <img src={five} />
                </div>
                <div className="list-inner3">
                  The website achieved&nbsp;
                  <span>higher rankings for 30+ targeted keywords</span>&nbsp;
                  related to the client's industry starting from scratch.
                </div>
              </li>
            </ol>
            <div></div>
          </div>
        </div>
      </div>

      <div className="counter">
        <div className="counter-head">
          <h1>Out Team Expertise</h1>
        </div>
        <div className="container">
          <Counterup />
        </div>
      </div>
      <div className="seocampaign-sec4">
        <div className="seocampaign-sec4-head">
          <h1>SOME OF OUR CASE STUDIES</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 stat-img">
              <img src={stat1} />
            </div>
            <div className="col-md-6 stat-text">
              <h1>381 calls booked in the month of August for KMC Hospital</h1>
              <p>
                Our goal was to increase the number of calls made through Google
                Business Profile, number of Footfalls in Hospital/Clinic and
                increase in number of reviews.
              </p>
              <p>
                In the month of August 381 Calls were made through Google
                Business Profile. An improvement of 27 % from the Previous
                Month.
              </p>
            </div>
          </div>
          <div className="row stat-sec-mid">
            <div className="col-md-6 stat-text2">
              <h1>
                189 People made use of Google Maps to visit the targeted
                location.
              </h1>
              <p>
                Our goal was to increase the number of people using maps for
                directions made through Google Business Profile, and number of
                Footfalls in Hospital/Clinic.
              </p>
              <p>
                189 People made use of Google Maps to visit the Hospital/Clinic.
                Around 21 % increase in the stats from previous month & more
                than 57.5 % Increase from previous year.
              </p>
            </div>
            <div className="col-md-6 stat-img2">
              <img src={stat2} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 stat-img">
              <img src={stat3} />
            </div>
            <div className="col-md-6 stat-text">
              <h1>
                70% increase in organic traffic and higher ranking for
                CrystalBiz.
              </h1>
              <p>
                Increased Organic Traffic: Within 1.5 months of implementing the
                SEO strategy, the client's website has experienced a 70%
                increase in organic traffic.
              </p>
              <p>
                Improved Keyword Rankings: The website achieved higher rankings
                for several targeted keywords related to the client's industry.
              </p>
              <p>
                Enhanced Online Visibility: The improved keyword rankings and
                increased organic traffic contributed to enhanced online
                visibility for CrystalBiz Website.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="seocampaign-sec5">
        <div className="seocampaign-sec5-head">
          <h1>What Are You Struggling To Grow?</h1>
        </div>
        <div className="container">
          <div className="row justify-content-center sec5-row">
            <div className="col-md-5">
              <div className="sec5">
                <div className="sec5-img">
                  <img src={google} />
                </div>
                <div className="sec5-text">
                  <h1>Not ranking on Google</h1>
                  <p>
                    Ranking on Google is a must for businesses in 2024. With 87%
                    of the search market share, your business needs to show up
                    on Google.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="sec5">
                <div className="sec5-img">
                  <img src={stocks} />
                </div>
                <div className="sec5-text">
                  <h1>Organic traffic is low</h1>
                  <p>
                    There’s nothing better than a highly targeted &
                    cost-effective traffic that gives you a competitive
                    advantage as a prominent brand in your space.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-between sec5-row">
            <div className="col-md-5">
              <div className="sec5">
                <div className="sec5-img">
                  <img src={dollar} />
                </div>
                <div className="sec5-text">
                  <h1>Ain't getting leads or sales</h1>
                  <p>
                    You may have a great product or service, but if no one knows
                    about it or if you fail to diversify your marketing
                    channels, you will have no sales.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="mystery-img">
                <img src={mystery} />
              </div>
            </div>
            <div className="col-md-5">
              <div className="sec5">
                <div className="sec5-img">
                  <img src={bag} />
                </div>
                <div className="sec5-text">
                  <h1>Current agency not delivering</h1>
                  <p>
                    Lack of communication, coordination, and transparency will
                    always lead to a bad result. Let us show you how we can
                    manage your expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center sec5-row">
            <div className="col-md-5">
              <div className="sec5">
                <div className="sec5-img">
                  <img src={mic} />
                </div>
                <div className="sec5-text">
                  <h1>SEO strategy not performing</h1>
                  <p>
                    Everybody is online and doing SEO, but only a few of them
                    are obtaining qualified traffic and ranking for their
                    keywords.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="sec5">
                <div className="sec5-img">
                  <img src={desktop} />
                </div>
                <div className="sec5-text">
                  <h1>Website is outdated</h1>
                  <p>
                    Your website design impacts how the audience perceives your
                    brand. Impactful user experience & Intuitive design should
                    be your priority.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec3-button">
            <Link>
              <button onClick={handleClickOpen}>Book Meeting</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="section-why-us">
        <div className=" section-why-us-head">
          <h1>Why Us & Not Others.</h1>
        </div>
        <div className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-md-4 ">
                <div className="why-us-sec ">
                  <h1>🥇 Premium Tools</h1>
                  <p>
                    We make use of premium tools like Ahrefs, SEMrush, Buzzsumo,
                    Keywordtool, & more.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>📈 Long Term Strategy</h1>
                  <p>
                    We focus on long term plans & achievements. This helps us
                    build a strong brand identity.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>🔥 Dedicated Team</h1>
                  <p>
                    Projects are handled by 4+ full-time employees with 3+
                    combined years of experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>📋 On-Demand Reports</h1>
                  <p>
                    We track all our activities with daily, weekly, monthly
                    reports & overall Results assessment.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>💎 Quality Results</h1>
                  <p>
                    We plan & execute tasks that drive Long-Term quality results
                    & Satisfaction of our clients.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>👤 Always on Support</h1>
                  <p>
                    We have always extended support to our clients beyond our
                    service & continue to do so.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>⭐ Ethical & Transparent</h1>
                  <p>
                    We don’t do any Gray-Hat or Black-Hat BS. We love our
                    clients & work for their best.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>🏆 Fast Implementation</h1>
                  <p>
                    We believe in Ideation. We Plan, Implement, Track, & Analyze
                    the entire process.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="why-us-sec ">
                  <h1>💼 Updated Regularly</h1>
                  <p>
                    We update our Clients business & Digital Channels with
                    Changes & Updates in the Digital world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec3-button">
          <Link >
          <button onClick={handleClickOpen}>Schedule Now </button>
          </Link>
        </div>
      </div>
      <div className="seocampaign-sec6">
        <div className="seocampaign-sec6-head">
          <h1>Unlike other agencies</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3 unlike-us">
              <div className="sec6-img">
                <img src={overwork} />
              </div>
              <div className="sec6-text">
                <h1>
                  {" "}
                  We don’t assign{" "}
                  <b>
                    <u> multiple projects </u>
                  </b>
                  to a{" "}
                  <b>
                    <u> single individual. </u>
                  </b>{" "}
                </h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec6-img">
                <img src={overpromise} />
              </div>
              <div className="sec6-text">
                <h1>
                  {" "}
                  We don’t
                  <b>
                    <u> overpromise results </u>
                  </b>
                  &
                  <b>
                    <u> under deliver </u>
                  </b>
                  to our clients.
                </h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec6-img">
                <img src={shortcut} />
              </div>
              <div className="sec6-text">
                <h1>
                  {" "}
                  We don’t take
                  <b>
                    <u> shortcuts </u>
                  </b>{" "}
                  or
                  <b>
                    <u> unethical techniques </u>
                  </b>
                  for short-term results.{" "}
                </h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec6-img">
                <img src={whyimage} />
              </div>
              <div className="sec6-text">
                <h1>
                  {" "}
                  We don’t
                  <b>
                    <u> outsource </u>
                  </b>
                  any of our client work to{" "}
                  <b>
                    <u> freelancers. </u>
                  </b>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marquee-sec">
        <div className="marquee2">
          <div className="marquee-head">
            <h1>Those Who We Work Along Side</h1>
          </div>
          <Marquee speed={scrollSpeed}>
            <span>
              {" "}
              <img src={grid1} />
            </span>
            <span>
              {" "}
              <img src={grid2} />
            </span>
            <span>
              {" "}
              <img src={grid3} />
            </span>
            <span>
              {" "}
              <img src={grid4} />
            </span>
            <span>
              <img src={grid5} />
            </span>
            <span>
              <img src={grid6} />
            </span>
            <span>
              <img src={grid7} />
            </span>
            <span>
              {" "}
              <img src={grid8} />
            </span>
            <span>
              {" "}
              <img src={grid9} />
            </span>
            <span>
              {" "}
              <img src={grid10} />
            </span>

            <span>
              {" "}
              <img src={grid11} className="sootsparsh-logo" />
            </span>
          </Marquee>
        </div>
      </div>
      <div className="seocampaign-sec7">
        <div className="container border-sec">
          <div className="seocampaign-sec7-head">
            <h1>Not Sure If SAAA CONSULTANTS Is For You?</h1>
            <p>Here are a couple of quick questions for you!</p>
          </div>
          <div className="row margin-sec">
            <ol>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>
                Are you new to running an online business and directionless?
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you having difficulty creating your online presence?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you trying to outperform your competitors but don't know
                how?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you trying to grow your organic traffic & brand recall?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you trying to increase your leads, conversions, or sales,
                but failing?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you trying to build backlinks by guest blogging or sponsored
                posts?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you trying to advertise on Facebook, Google, & other
                channels?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you getting less ROI on your overall marketing efforts?{" "}
              </li>
              <li>
                {" "}
                <div className="check">
                  <img src={check} />
                </div>{" "}
                Are you having trouble with your existing marketing agency?
              </li>
            </ol>
            <div></div>
          </div>
        </div>
      </div>
      <div className="seocampaign-sec8">
        <div className="seocampaign-head">
          <img src={doodle} />

          <div className="seocampaign-yellow-rect">
            <img src={yellowrect} />
            <div className="seocampaign-text">
              <h1>
                <b>Interested?</b>
              </h1>
              <h1>
                <b>Reach Out To Us </b>
              </h1>
              <p>
                Unlike other agencies, we don’t charge pretty high & deliver
                less and even worse, we don’t charge very low & undertake
                outdated tactics & activities.{" "}
              </p>
              <div className="sec3-button">
                <Link >
                  <button onClick={handleClickOpen}>Book Meeting</button>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
