import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import NavBar from "./component/Navbar/Navbar";
import ContactForm from "./contactform";
import Home from "./component/home/Home";
import About from "./component/about/about";
import Teammembers from "./component/Teammembers/Teammembers";
import "animate.css";
import Error from "./component/error/404";
import Graphic from "./component/plans/Graphic";
import Uiux from "./component/plans/Uiux";
import SmmPlans from "./component/plans/SmmPlans";
import WebPlans from "./component/plans/WebPlans";
import FasPlans from "./component/plans/Fasplan";
import LegalCompliancePlans from "./component/plans/Legalplan";
import SEOCampaign from "./component/seocampaign/seocampaign";
import CaseStudies from "./component/casestudies/casestudies";
import LandingVideo from "./component/Landingpage/landingpage";
import OurInfluencers from "./component/OurInfluencers/OurInfluencers";

const App = () => {
  return (
    <>
      <div className="App">
        <Router>
          <Routes></Routes>
          <NavBar />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Suspense fallback={<div>Loading...</div>}><Home /></Suspense>} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/about" element={<About />} />
              <Route path="/contactform" element={<ContactForm />} />
              <Route path="*" element={<Error />} />
              <Route path="/services/designing-branding" element={<Graphic />} />
              <Route path="/services/ui-ux" element={<Uiux />} />
              <Route path="/services/digital-marketing" element={<SmmPlans />} />
              <Route path="/services/web-development" element={<WebPlans />} />
              <Route path="/services/financial-advisory" element={<FasPlans />} />
              <Route
                path="/services/legal-compliance"
                element={<LegalCompliancePlans />}
              />
              <Route path="/seocampaign" element={<SEOCampaign />} />
              <Route path="/our-team" element={<Teammembers />} />
              <Route path="/case-studies" element={<CaseStudies />} />
              <Route path="/Landingvideo" element={<LandingVideo />} />
              <Route path="/our-influencers" element={<OurInfluencers />} />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </>
  );
};

export default App;
