import React, { useState, useEffect, useRef } from "react";

import top from "../../assets/whatsapp.png";

import "animate.css/animate.min.css";
import Footer from "../footer/footer";
import AOS from "aos";
import "aos/dist/aos.css";

import "./plan.css";

import serv1 from "../../assets/Services/bg.webp";
import business from "../../assets/Services/businessmodeling.webp";
import analytics from "../../assets/Services/analyticsandvaluation.webp";
import inventor from "../../assets/Services/inventorlist.webp";
import forcasting from "../../assets/Services/forcasting.webp";
import fashero from "../../assets/Services/fashero.svg";
import fasprocess from "../../assets/Services/Horizontal FAS.svg";
import fasprocessverticle from "../../assets/Services/Vertical FAS.svg";
import investorsrelation from "../../assets/Services/investorsrelation.webp";
import { Link } from "react-router-dom";

const FASPlans = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);
  const [revealedItems, setRevealedItems] = useState([]);
  const [lineWidth, setLineWidth] = useState("0");
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const sectionRef2 = useRef(null);
  const [isVisible3, setIsVisible3] = useState(false);
  const sectionRef3 = useRef(null);
  const [isVisible4, setIsVisible4] = useState(false);
  const sectionRef4 = useRef(null);
  const [isVisible5, setIsVisible5] = useState(false);
  const sectionRef5 = useRef(null);
  const [isVisible6, setIsVisible6] = useState(false);
  const sectionRef6 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible3(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible4(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer5 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible5(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );
    const observer6 = new IntersectionObserver(
      ([entry]) => {
        setIsVisible6(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer3.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer4.observe(sectionRef4.current);
    }
    if (sectionRef5.current) {
      observer5.observe(sectionRef5.current);
    }
    if (sectionRef6.current) {
      observer6.observe(sectionRef6.current);
    }

    // Cleanup

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer3.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer4.unobserve(sectionRef4.current);
      }
      if (sectionRef5.current) {
        observer5.unobserve(sectionRef5.current);
      }
      if (sectionRef6.current) {
        observer6.unobserve(sectionRef6.current);
      }
    };
  }, []);

  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsVisible(true);
  //         observer.disconnect();
  //       }
  //     },
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.5, // Adjust as needed
  //     }
  //   );

  //   if (targetRef.current) {
  //     observer.observe(targetRef.current);
  //   }

  //   return () => {
  //     if (targetRef.current) {
  //       observer.unobserve(targetRef.current);
  //     }
  //   };
  // }, []);
  // const [hasAnimated, setHasAnimated] = useState(false);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting && !hasAnimated) {
  //         setIsVisible(true);
  //         setHasAnimated(true);
  //         observer.disconnect();
  //       }
  //     },
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.5, // Adjust as needed
  //     }
  //   );

  //   if (targetRef.current) {
  //     observer.observe(targetRef.current);
  //   }
  //   return () => {
  //     if (targetRef.current) {
  //       observer.unobserve(targetRef.current);
  //     }
  //   };
  // }, [hasAnimated]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update isVisible state based on the entry's intersection ratio
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is in the viewport
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  const handleScroll = () => {
    const timelineItems = document.querySelectorAll(".timeline-items");
    timelineItems.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      if (rect.top < window.innerHeight * 0.75) {
        setRevealedItems((prevItems) =>
          prevItems.includes(index) ? prevItems : [...prevItems, index]
        );
        setLineWidth(`${(index / 6) * 60}%`);
      }
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  return (
    <>
      <div className="services web-plans">
        <div className="back-2-top">
          <a
            href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
            target="_blank"
          >
            <img src={top} alt="Whatsapp" />
          </a>
        </div>
        <div className="main-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 web-hero-text">
                <div className="web-hero-div">
                  <p>Financial Advisory Services</p>
                  <h1>
                    We offer a comprehensive suite of solutions to help clients
                    navigate the intricacies of the ever-evolving financial
                    landscape.
                  </h1>
                </div>
                <div className="service-btnhome smm-hero-btn">
                  <div className="service-actual-btn plan-slider-btn">
                    <Link to="/contact" smooth>
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5 web-hero-img">
                <img src={fashero} alt="Financial Hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="web-timeline">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="webplan-timeline-content">
                  <h2>Process</h2>
                  <p>
                    At SAAA, we redefine digital experiences through thoughtful
                    UI/UX design. Our team meticulously crafts interfaces that
                    seamlessly blend aesthetics with functionality, ensuring
                    every click and swipe tells a story. With user-centric
                    design principles, we elevate your digital presence.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 fasprocess-img">
                <img src={fasprocess} className="fashorizontal" alt="Process" />
                <img
                  src={fasprocessverticle}
                  className="fasverticle"
                  alt="Process"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="all-serv-sec">
          <div className="serv1-full-sec">
            <div className={`serv1-sec `} ref={sectionRef}>
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Financial Marketing</h1>
                  <p>
                    At SAAA Consultants, we lead the charge in guiding
                    businesses to financial success through the strategic
                    application of finance modeling. Serving as the backbone of
                    financial planning and analysis, our cutting-edge techniques
                    create dynamic models that simulate various scenarios,
                    providing invaluable insights for data-driven
                    decision-making. With a focus on forecasting future
                    financial performance, our detailed models consider market
                    trends, revenue streams, and operational costs. Leveraging
                    technology and industry expertise, we construct robust
                    financial models that empower businesses to proactively
                    address challenges, capitalize on opportunities, and enhance
                    overall financial performance. Embrace financial resilience
                    and success with SAAA Consultants, where strategy meets
                    precision for a prosperous future.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible ? "is-visible" : ""
                }`}
              >
                <img src={serv1} alt="Service 1" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div className={`serv2-sec`} ref={sectionRef2}>
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <img src={business} alt="Service 1" className="lozad" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible2 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Business Modelling</h1>
                  <p>
                    In the dynamic realm of commerce, SAAA Consultants champions
                    businesses with strategic business modeling, a pivotal tool
                    for sustainable growth and adaptability. Our expertise lies
                    in crafting tailored business models that analyze
                    organizational structures, processes, and interactions.
                    Going beyond conventional approaches, we integrate
                    innovative technologies and forward-thinking strategies.
                    Partnering with us provides businesses with a comprehensive
                    understanding of operations, customer interactions, and
                    market positioning. Our models not only reflect the present
                    but anticipate future trends. SAAA Consultants' business
                    modeling is the key to optimizing processes, exploring
                    revenue streams, and enhancing customer experiences,
                    offering a roadmap for success in a rapidly changing
                    marketplace.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div
              className={`serv1-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef3}
            >
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Forecasting / Budgeting</h1>
                  <p>
                    SAAA Consultants navigates the intricate dance of business
                    management, mastering forecasting and budgeting for
                    financial prowess. Our predictive models peer into the
                    future, considering market trends and industry shifts. This
                    insight empowers clients to make decisions today that
                    resonate tomorrow. Complemented by our artful budgeting,
                    resources are allocated judiciously, transforming budgets
                    into strategic tools. Together, forecasting and budgeting
                    form the bedrock of financial resilience. SAAA Consultants
                    empowers businesses not just to weather uncertainties but to
                    thrive amidst them. Join us as we navigate the future with
                    strategic precision, turning challenges into opportunities.
                    With SAAA, the future is a canvas painted with financial
                    success.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible3 ? "is-visible" : ""
                }`}
              >
                <img src={forcasting} alt="Service 1" className="lozad" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div
              className={`serv2-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef4}
            >
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <img src={analytics} alt="Service 1" className="lozad" />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible4 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Analytics & Valuation</h1>
                  <p>
                    In the age of information, SAAA Consultants wields the magic
                    wand of analytics, turning raw data into a strategic
                    advantage. Beyond mere charts, analytics is an art at SAAA,
                    where every data point tells a story. Our expertise delves
                    deep, extracting meaningful patterns from customer behavior,
                    market dynamics, and operational efficiency. From predictive
                    analytics foreseeing trends to prescriptive analytics
                    guiding choices, we craft bespoke approaches, leveraging
                    advanced tools to distill complex data into actionable
                    insights, offering a competitive edge. Partnering with SAAA
                    unlocks a data-driven future, where decisions are backed by
                    transformative insights. SAAA Consultants specializes in
                    delivering precise valuations enabling clients to make
                    informed choices confidently. Trusted partners in addressing
                    complex financial and tax reporting valuation requirements,
                    our services span financial reporting, mergers,
                    acquisitions, taxation planning, and compliance, providing
                    Business Valuation, Valuation Advisory Services, Asset
                    Valuation, Mergers & Acquisitions, and Financial Reporting.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="serv1-full-sec">
            <div
              className={`serv1-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef5}
            >
              <div
                className={`serv1-tex-div fade-in-section ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <div className="serv1-text">
                  <h1>Investor List</h1>
                  <p>
                    Unlock the door to investment opportunities with SAAA
                    Consultants' Investors List service. In the dynamic realm of
                    business, connecting with the right investors is a
                    game-changer. Our service goes beyond a mere list; it's a
                    curated portfolio of potential backers tailored to your
                    business profile. SAAA's team harnesses market insights and
                    industry expertise to compile a comprehensive list of
                    potential investors who align with your vision. Whether
                    you're a startup seeking seed funding or an established
                    enterprise eyeing expansion, our Investors List service
                    provides a strategic roadmap to pitch your ideas. Elevate
                    your fundraising journey with SAAA Consultants—where
                    precision meets possibilities, turning aspirations into
                    investment realities.
                  </p>
                </div>
              </div>
              <div
                className={`serv1-tex-img other-element ${
                  isVisible5 ? "is-visible" : ""
                }`}
              >
                <img src={inventor} alt="Service 1" className="lozad" />
              </div>
            </div>
          </div>
          <div className="serv2-full-sec">
            <div
              className={`serv2-sec ${isVisible ? "animate" : ""}`}
              ref={sectionRef6}
            >
              <div
                className={`serv2-tex-img fade-in-section ${
                  isVisible6 ? "is-visible" : ""
                }`}
              >
                <img
                  src={investorsrelation}
                  alt="Service 1"
                  className="lozad"
                />
              </div>
              <div
                className={`serv2-tex-div other-element ${
                  isVisible6 ? "is-visible" : ""
                }`}
              >
                <div className="serv2-text">
                  <h1>Investors Relation</h1>
                  <p>
                    In the rapidly evolving business environment, having strong
                    investor relations is crucial. Investor relations services
                    involve managing communication between a company and its
                    investors, both current and potential. Our IR services are
                    dedicated to building trust, transparency, and a strong
                    relationship with your valued shareholders and stakeholders.
                    These include timely and accessible updates on financial
                    performance through the provision of monthly, quarterly and
                    annual reports, and easy access to key financial
                    information, events, and presentations, ensuring that
                    stakeholders can stay informed about your strategic
                    direction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-btnhome">
          <div className="service-actual-btn fas-chat-btn">
            <Link to="/contact" smooth>
              <span>Let's Chat</span>
              <i className="fa-solid fa-arrow-right arrow-services"></i>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default FASPlans;
