import React from "react";
import donutvid from "../../assets/donut.mp4";
import "./404.css";

const errorPage = () => {
  return (
    <div className="erropage">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-6 mx-auto d-flex justify-content-center align-items-center error-head-text">
              <h1 className="error">4</h1>
              <video
                className="background-video-error"
                src={donutvid}
                autoPlay
                loop
                muted
              />
              <h1 className="error-2">4</h1>
            </div>
          </div>
          <div className="btm-text-1">
            <h1>404 ERROR</h1>
            <h1>Glicth in the matrix</h1>
            <p>
              Oops! It seems like you've ventured into uncharted territory. We
              apologize for the inconvenience and hope you find what you're
              looking for soon!
            </p>
          </div>
          <div className="back-btn">
            <a href="Home">
              Back To Reality <i className="fa-solid fa-arrow-right arrow-404"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default errorPage;
