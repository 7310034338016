import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import phone from "../../assets/Home/phone.svg";
import mail from "../../assets/Home/mail.svg";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("");
  const [activeColor, setActiveColor] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navButton = useRef(null);

  const [visible, setVisible] = useState(true);
  const [isNavShowing, setIsNavShowing] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;

  //     if (currentScrollPos > prevScrollPos) {
  //       setVisible(false);
  //     } else if (currentScrollPos < prevScrollPos) {
  //       setVisible(true);
  //     }

  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [prevScrollPos]);

  const copyTextToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Number copied to clipboard:", text);
        // Show a message to the user that the text has been copied
        alert("Number copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  const copyEmailToClipboard = () => {
    const email = "info@saaaconsultants.com"; // Replace with your email address
    copyTextToClipboard(email);
  };

  const copyNumberToClipboard = () => {
    const number = "+91 7977895134"; // Replace with the number you want to copy
    copyTextToClipboard(number);
  };
  const toggleMobileNav = () => {
    const navbarCollapse = document.querySelector(".navbar-collapse");

    if (navbarCollapse) {
      navbarCollapse.classList.toggle("show");
    }

    // Assuming setIsNavShowing is defined elsewhere in your code
    setIsNavShowing((prev) => !prev);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setActiveColor();
    setIsNavShowing(false);
  };

  return (
    <div className="main-navbar">
      <nav
        className={`navbar navbar-expand-lg ${visible ? "visible" : "hidden"}`}
        id="navbar"
      >
        <div className="container-fluid">
          <Link
            to="/"
            className="navbar-brand logo "
            onClick={() => setIsNavShowing(false)}
          >
            <img src={logo} alt="Logo" className="logo" />
          </Link>

          <button
            className="nav__toggle-btn navbar-toggler bg-white"
            onClick={toggleMobileNav}
          >
            {isNavShowing ? <ImCross /> : <GiHamburgerMenu />}
          </button>

          <div
            className={`collapse navbar-collapse ${isNavShowing ? "show" : ""}`}
          >
            <ul
              className={`navbar-nav  nav-links ${
                isNavShowing ? "show__nav" : "hide__nav"
              }`}
            >
              <li className={`nav-item ${activeLink === "/" ? "active" : ""}`}>
                <Link
                  to="/"
                  className="nav-link"
                  onClick={() => handleLinkClick("/")}
                  style={{
                    color: activeLink === "/" ? "#FBB13C" : "",
                    borderBottom:
                      activeLink === "/" ? "2px solid #FBB13C" : "none",
                  }}
                >
                  Home
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <div
                  class="dropdown-menu drop-full"
                  aria-labelledby="navbarDropdown"
                >
                  <Link class="dropdown-item drop-1" to="/services/digital-marketing">
                    Digital Marketing
                  </Link>
                  <Link class="dropdown-item drop-1" to="/services/web-development">
                    Web Development
                  </Link>
                  <Link class="dropdown-item drop-1" to="/services/designing-branding">
                    Designing / Branding
                  </Link>
                  <Link class="dropdown-item drop-1" to="/services/ui-ux">
                    UI/UX
                  </Link>
                  {/* <Link class="dropdown-item drop-1" to="/services/financial-advisory">
                    Financial Advisory Services
                  </Link> */}
                  <Link class="dropdown-item drop-1" to="/services/legal-compliance">
                    Legal Compliance
                  </Link>
                </div>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/about" ? "active" : ""
                }`}
              >
                <Link
                  to="/about"
                  className="nav-link "
                  onClick={() => handleLinkClick("/about")}
                  style={{
                    color: activeLink === "/about" ? "#FBB13C" : "",
                    borderBottom:
                      activeLink === "/about" ? "2px solid #FBB13C" : "none",
                  }}
                >
                  About Us
                </Link>
              </li>
              
              <li
                className={`nav-item ${
                  activeLink ===  "/our-influencers"  ? "active" : ""
                }`}
              >
                <Link
                  to="/our-influencers"
                  className="nav-link "
                  onClick={() => handleLinkClick( "/our-influencers" )}
                  style={{
                    color: activeLink === "/our-influencers" ? "#FBB13C" : "",
                    borderBottom:
                      activeLink ===  "/our-influencers" 
                        ? "2px solid #FBB13C"
                        : "none",
                  }}
                >
                  Our Influencers
                </Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/our-team" ? "active" : ""
                }`}
              >
                <Link
                  to="/our-team"
                  className="nav-link "
                  onClick={() => handleLinkClick("/our-team")}
                  style={{
                    color: activeLink === "/our-team" ? "#FBB13C" : "",
                    borderBottom:
                      activeLink === "/our-team"
                        ? "2px solid #FBB13C"
                        : "none",
                  }}
                >
                  Our Team
                </Link>
              </li> 
              <li
                className={`nav-item ${
                  activeLink === "/casestudies" ? "active" : ""
                }`}
              >
                <Link
                  to="/case-studies"
                  className="nav-link "
                  onClick={() => handleLinkClick("/case-studies")}
                  style={{
                    color: activeLink === "/case-studies" ? "#FBB13C" : "",
                    borderBottom:
                      activeLink === "/case-studies"
                        ? "2px solid #FBB13C"
                        : "none",
                  }}
                >
                  Case Studies
                </Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/contact" ? "active" : ""
                }`}
              >
                <Link
                  to="/contact"
                  className="nav-link"
                  onClick={() => handleLinkClick("/contact")}
                  style={{
                    color: activeLink === "/contact" ? "#FBB13C" : "",
                    borderBottom:
                      activeLink === "/contact" ? "2px solid #FBB13C" : "none",
                  }}
                >
                  Contact
                </Link>
              </li>
              </ul>

              <div className="nav-item right-navbar">
                <Link

                  className="nav-link-icon"
                  onClick={() => {
                    setIsNavShowing((prev) => !prev);
                    copyNumberToClipboard();
                  }}
                >
                  <img src={phone} alt="Call" className="phone" />{" "}
                </Link>
                <Link
                  className="nav-link-icon-mail"
                  
                  onClick={() => {
                    setIsNavShowing((prev) => !prev);
                    window.open("mailto:info@saaaconsultants.com?cc=info@saaaconsultants.com");

                  }}
                >
                  <img src={mail} alt="Mail" className="mail" />
                </Link>
              </div>
          </div>
        
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
